import React, { useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useRequest } from "../utils";

const NotificationList = () => {
  const req = useRequest();
  const [data, setData] = useState([]);

  const [filteredItems, setFilteredItems] = useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getNotifications();
  }, []);

  const tooltipReadNotification = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Visualizza notifica
    </Tooltip>
  );

  const getNotifications = async () => {
    let { data: res } = await req.get(`/notification`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    setData(res.data);
    setFilteredItems(res.data);
  };

  const goFilter = async (filter) => {
    let tmpFiltered = data.filter(
      (item) =>
        item.nome && item.nome.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredItems(tmpFiltered);
    setFilterText(filter);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        setFilteredItems(data);
      }
    };

    return (
      <input
        placeholder="Cerca per nome"
        className="border border-solid rounded-lg border-gray pl-4"
        type="text"
        value={filterText}
        onChange={(e) => {
          goFilter(e.target.value);
        }}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Id notifica",
      selector: (row) => row.id_notifica,
      sortable: true,
    },
    {
      name: "Testo",
      selector: (row) => row.testo,
      sortable: true,
    },
    {
      name: "Data Invio",
      selector: (row) => {
        const date = row.dataAgg.slice(0, 10);
        const dateArray = date.split("-");
        if (dateArray.length == 3) {
          return dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0];
        } else return "Non disponibile";
      },
      sortable: true,
    },

    {
      name: "Visualizza",
      selector: (row) => {
        return (
          <div className="flex justify-evenly">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={tooltipReadNotification}
            >
              <Button
                className="mx-2"
                onClick={() =>
                  navigate("/notification/read/" + row.id_notifica)
                }
              >
                <FaEye />
              </Button>
            </OverlayTrigger>
          </div>
        );
      },
      sortable: true,
    },
  ];

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "Tutte",
    rowsPerPageText: "Righe per pagina",
    rangeSeparatorText: "di",
  };

  const customStyles = {
    subHeader: {
      style: {
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      },
    },
    pagination: {
      style: {
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      },
    },
    rows: {
      cursor: "pointer",
    },
  };

  return (
    <div>
      <div className="w-full h-full justify-center items-center container mx-auto align-middle">
        <DataTable
          customStyles={customStyles}
          columns={columns}
          data={filteredItems}
          pagination={true}
          paginationComponentOptions={paginationComponentOptions}
          striped
          paginationPerPage={8}
          defaultSortFieldId={1}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          pointerOnHover={true}
        />
      </div>
    </div>
  );
};

export default NotificationList;
