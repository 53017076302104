import React from "react";
import { Toaster } from "react-hot-toast";
import { useRequest } from "../utils";

import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import NotificationList from "./notifiche_list";

const HomeNotification = () => {
  const req = useRequest();
  const navigate = useNavigate();

  return (
    <div className="flex rounded-tr-2xl rounded-br-2xl flex-col w-full h-full bg-secondary bg-opacity-60 p-2">
      <div id="header" className="flex flex-col w-full mb-4">
        <Toaster />
        <h1 className="text-[#802727] text-2xl">Gestisci notifiche</h1>
        <NotificationList />
        <div className="flex justify-end m-3">
          <Button
            className="bg-[#802727] text-white hover:bg-[#802727] text-white"
            onClick={() => navigate("/notification/create")}
          >
            Invia una notifica ora
          </Button>
         {/*  <Button
            className="bg-[#802727] text-white hover:bg-[#802727] text-white ml-4"
            onClick={() => navigate("/notification/programmed")}
          >
            Gestisci notitiche programmate
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default HomeNotification;
