import React from "react";
import { useRequest } from "../utils";


import { useNavigate } from "react-router-dom";
import ChatList from "./chat_list";

const HomeChatList = () => {
  const req = useRequest();
  const navigate = useNavigate();

  return (
    <div className="flex rounded-tr-2xl rounded-br-2xl flex-col w-full h-full bg-secondary bg-opacity-60 p-2">
      <div id="header" className="flex flex-col w-full mb-4">
        <h1 className="text-[#802727] text-2xl">Messaggi</h1>
        <ChatList />
        <div className="flex justify-end m-3">
        </div>
      </div>
    </div>
  );
};

export default HomeChatList;
