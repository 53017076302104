import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  OverlayTrigger,
  Toast,
  ToastContainer,
  Tooltip,
} from "react-bootstrap";
import { FaEye, FaEyeSlash, FaInfo } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { Loading, useRequest } from "../../utils";

const UserCU = () => {
  const req = useRequest();
  const { id } = useParams();
  const isUpdate = id != null;
  const [user, setUser] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [sesso, setSesso] = useState("-");
  const [dataDiNascita, setdataDiNascita] = useState("");
  const [cittaDiNascita, setCittaDiNascita] = useState("");
  const [provinciaDiNascita, setProvinciaDiNascita] = useState("");
  const [cf, setCf] = useState("");
  const [telefono, setTelefono] = useState("");
  const [indirizzoResidenza, setIndirizzoResidenza] = useState("");
  const [cittaResidenza, setCittaResidenza] = useState("");
  const [cap, setCap] = useState("");
  const [tipoReg, setTipoReg] = useState("-");
  const [tipoPasto, setTipoPasto] = useState("-");
  const [studio, setStudio] = useState("");
  const [abbonato, setAbbonato] = useState("-");
  const [attivo, setAttivo] = useState("-");

  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("error");
  const [alertMessage, setAlertMessage] = useState("");

  const [dieta, setDieta] = useState({});
  const [oldDieta, setOldDieta] = useState();
  const [storicoDiete, setStoricoDiete] = useState([]);
  const [diete, setDiete] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=\S+$).{6,}$/;

  const [showPassword, setShowPassowrd] = useState(false);
  const [blockPassword, setBlockPassowrd] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (isUpdate) {
        await getUser();
        await getDieta();
        await getAllDiete();
      }else{
        setEmail("");
        setPassword("");
        setNome("");
        setCognome("");
        setSesso("-");
        setdataDiNascita("");
        setCittaDiNascita("");
        setProvinciaDiNascita("");
        setCf("");
        setTelefono("");
        setIndirizzoResidenza("");
        setCittaResidenza("");
        setCap("");
        setTipoReg("-");
        setTipoPasto("-");
        setStudio("");
        setAbbonato("-");
        setAttivo("-");
        setDieta({});
        setOldDieta();
        setStoricoDiete([]);
        setDiete([]);
        
      }
    };
    getData();
    setLoading(false);
  }, []);

  const getDieta = async () => {
    let res = await req.get(`/diet/byuser/${id}`);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;

    if (res.data.data.length > 0) {
      var dietaAttiva = res.data.data.find((dieta) => dieta.toShow == "S");
      if (dietaAttiva != null) {
        setDieta(dietaAttiva);
        setOldDieta(dietaAttiva);
        var storico = res.data.data.filter((dieta) => dieta.toShow == "N");
        setStoricoDiete(storico);
      } else {
        setStoricoDiete(res.data.data);
      }
    }
  };

  const onSave = async () => {
    setLoading(true);


    if (email === "" || email === null || email === undefined) {
      setShowAlert(true);
      setAlertType("danger");
      setAlertMessage("L'indirizzo email è obbligatorio");
      setLoading(false);
      return;
    }

    user.nome = nome;
    user.cognome = cognome;
    user.email = email;
    user.password = null;


    if (password !== "") {
      if (password.length > 2) {
/*         if (regex.test(password)) {
 */          user.password = password;
        /*         } else {
                  setShowAlert(true);
                  setAlertType("danger");
                  setAlertMessage(
                    "La password deve contenere almeno un numero, una lettera maiuscola e una minuscola."
                  );
                  setLoading(false);
                  return;
                } */
      } else {
        setShowAlert(true);
        setAlertType("danger");
        setAlertMessage("La password deve avere almeno 3 caratteri");
        setLoading(false);
        return;
      }
    }
    user.sesso = sesso;
    user.dataDiNascita = dataDiNascita;
    user.cittaDiNascita = cittaDiNascita;
    user.provinciaDiNascita = provinciaDiNascita;
    user.telefono = telefono;
    user.indirizzoResidenza = indirizzoResidenza;
    user.cittaResidenza = cittaResidenza;
    user.cap = cap;
    user.cf = cf;
    user.tipoReg = tipoReg != "-" ? tipoReg : null;
    user.abbonato = abbonato != "-" ? abbonato : null;
    user.studio = studio;
    user.tipoPasto = tipoPasto != "-" ? tipoPasto : null;
    user.tipoUtente = "U";
    user.attivo = attivo;

    console.log("USER", user);

    try {
      if (isUpdate) {
        let { data: res } = await req.put(`/user`, {
          id_utenteApp: localStorage.getItem("idUtente"),
          user: user,
        });
        if (oldDieta == null || oldDieta.id_dieta != dieta.id_dieta) {
          let { data: res2 } = await req.put(`diet/associateToUser/` + id, {
            idDieta: dieta.id_dieta,
            idDietaToRemove: oldDieta != null ? oldDieta.id_dietaUtente : "-1",
          });
        }
        setPassword("");
      } else {
        let { data: res } = await req.post(`/user`, {
          id_utenteApp: localStorage.getItem("idUtente"),
          user: user,
        });
      }
      setAlertType("success");
      setShowAlert(true);
      setAlertMessage("Modifica effettuata con successo!");
      if (isUpdate) {
        getUser();
        getDieta();
      }
      setLoading(false);
    } catch (e) {
      setShowAlert(true);
      setAlertType("danger");
      setAlertMessage("Qualcosa è andato storto! Modifiche non salvate");
      setLoading(false);
    }
  };

  const getAllDiete = async () => {
    let res = await req.get(`/diet/all`);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    setDiete(res.data.data);
  };

  const getUser = async () => {
    let res = await req.get(`/user/${id}`);
    console.log(res.data);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;

    let utente = res.data.data;
    if (utente.password != null && utente.password !== "") {
      setBlockPassowrd(true);
    }
    setNome(utente.nome);
    setSesso(utente.sesso);
    setCognome(utente.cognome);
    setStudio(utente.studio);
    setdataDiNascita(utente.dataDiNascita);
    setCittaDiNascita(utente.cittaDiNascita);
    setProvinciaDiNascita(utente.provinciaDiNascita);
    setCf(utente.cf);
    setEmail(utente.email);
    setTelefono(utente.telefono);
    setIndirizzoResidenza(utente.indirizzoResidenza);
    setCittaResidenza(utente.cittaResidenza);
    setCap(utente.cap);
    setTipoReg(utente.tipoReg);
    setAbbonato(utente.abbonato);
    setTipoPasto(utente.tipoPasto);
    setAttivo(utente.attivo);
    setUser(utente);
  };
  const tooltipBlockPassword = (props) => (
    <Tooltip id="button-tooltipBlock" {...props}>
      Non è possibile vedere o modificare la password di un utente che ha gia un
      account attivo sull'app
    </Tooltip>
  );
  const tooltipInsertPassword = (props) => (
    <Tooltip id="button-tooltipInsert" {...props}>
      L'utente non ha ancora creato un account sull'app, assegna una nuova
      password e salva l'account. La password dovrà contenere almeno un numero,
      una lettera maiuscola e una minuscola e avere una lunghezza minima di 6
      caratteri. Al salvataggio verrà inviata una email all'utente con le
      credenziali per accedere all'app. N.B. segna la nuova password su un
      post-it, cosicche se ci sono problemi con la mail potrai comunicare tu
      stesso la password!
    </Tooltip>
  );
  return (
    <div className="w-full">
      <ToastContainer className="p-3" position="top-end">
        <Toast
          bg={alertType}
          delay={3000}
          autohide
          onClose={() => setShowAlert(false)}
          show={showAlert}
        >
          <Toast.Header>
            {alertType == "danger" ? "Attenzione!" : "Perfetto!"}
          </Toast.Header>
          <p className="m-2">{alertMessage}</p>
          <hr />
        </Toast>
      </ToastContainer>
      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <ModalHeader>Associa una dieta all'untente </ModalHeader>
        <ModalBody>
          <div className="flex flex-col">
            <div className="mb-4 mt-2">
              <p className="text-2xl font-medium text-center ">Alternative</p>
              <div className="grid grid-cols-2 max-sm:grid-cols-1 grid-rows-1 gap-1 w-fulloverflow-y-scroll ">
                {diete.map((singleDieta) => {
                  return (
                    <Card
                      key={singleDieta.id_dieta}
                      className="m-2  bg-secondary/50"
                    >
                      <Card.Body className="text-center flex ">
                        <div className="text-center  items-center flex">
                          <div className="p-2">
                            <div key={`default-checkbox`} className="m-1">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox"
                                defaultChecked={
                                  singleDieta.id_dieta == dieta.id_dieta
                                }
                                checked={singleDieta.id_dieta == dieta.id_dieta}
                                onChange={() => {
                                  setDieta(singleDieta);
                                }}
                              />
                            </div>
                          </div>
                          <div className="p-1 flex justify-center items-center  text-xl">
                            {singleDieta.tipo}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  );
                })}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="text"
            color="green"
            onClick={handleClose}
            className="mr-1"
          >
            <span>Chiudi</span>
          </Button>
          <Button
            variant="text"
            color="green"
            onClick={handleClose}
            className="mr-1"
          >
            <span>Salva</span>
          </Button>
        </ModalFooter>
      </Modal>
      <div className="flex flex-col items-center w-full h-full p-1 ">
        <Loading className="my-auto" loading={loading}>
          <div id="header" className="flex flex-col w-full ">
            <h1 className="text-[#802727] text-4xl">
              {isUpdate ? "Modifica utente" : "Crea una nuovo utente"}
            </h1>
          </div>
          <div className="w-full ">
            <div className="flex max-md:flex-col mx-1">
              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <Form.Label htmlFor="inputnome" className="text-lg">
                    Nome:
                  </Form.Label>
                  <Form.Control
                    defaultValue={nome}
                    type="text"
                    id="inputnome"
                    onChange={(e) => {
                      setNome(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <Form.Label htmlFor="inputCognome" className="text-lg">
                    Cognome:
                  </Form.Label>
                  <Form.Control
                    defaultValue={cognome}
                    type="text"
                    id="inputCognome"
                    onChange={(e) => {
                      setCognome(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <Form.Label htmlFor="inputemail" className="text-lg">
                    Email:
                  </Form.Label>
                  <Form.Control
                    defaultValue={email}
                    type="text"
                    id="inputemail"
                    className="border-amber-900"
                    disabled={isUpdate ? true : false}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <div className="flex items-center justify-between mr-5">
                    <Form.Label htmlFor="inputPassword" className="text-lg">
                      Password:
                    </Form.Label>
                    {blockPassword ? (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tooltipBlockPassword}
                      >
                        <Button className="rounded-full border-1">
                          <FaInfo></FaInfo>
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tooltipInsertPassword}
                      >
                        <Button className="rounded-full border-1">
                          <FaInfo></FaInfo>
                        </Button>
                      </OverlayTrigger>
                    )}
                  </div>

                  <div className="flex items-center ">
                    <Form.Control
                      defaultValue={password}
                      type={showPassword ? "text" : "password"}
                      className="border-amber-900 mr-2"
                      id="inputPassword"
                      disabled={blockPassword}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    {blockPassword ? null : showPassword ? (
                      <FaEyeSlash
                        onClick={() => {
                          setShowPassowrd(false);
                        }}
                      ></FaEyeSlash>
                    ) : (
                      <FaEye
                        onClick={() => {
                          setShowPassowrd(true);
                        }}
                      ></FaEye>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex max-md:flex-col mx-1">
              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <Form.Label htmlFor="inputDataDiNascit" className="text-lg">
                    Data di nascita(gg/mm/aaaa):
                  </Form.Label>
                  <Form.Control
                    defaultValue={dataDiNascita}
                    type="text"
                    id="inputDataDiNascit"
                    onChange={(e) => {
                      setdataDiNascita(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <Form.Label htmlFor="inputCittaDiNascita" className="text-lg">
                    Citta di nascita:
                  </Form.Label>
                  <Form.Control
                    defaultValue={cittaDiNascita}
                    type="text"
                    id="inputCittaDiNascita"
                    onChange={(e) => {
                      setCittaDiNascita(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <Form.Label
                    htmlFor="inputProvinciaDiNascita"
                    className="text-lg"
                  >
                    Provincia di nascita:
                  </Form.Label>
                  <Form.Control
                    defaultValue={provinciaDiNascita}
                    type="text"
                    id="inputProvinciaDiNascita"
                    onChange={(e) => {
                      setProvinciaDiNascita(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <Form.Label htmlFor="inputTelefono" className="text-lg">
                    Telefono:
                  </Form.Label>
                  <Form.Control
                    defaultValue={telefono}
                    type="text"
                    id="inputTelefono"
                    onChange={(e) => {
                      setTelefono(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex max-md:flex-col mx-1">
              <div className="flex flex-col basis-1/4 p-2">
                <div className="px-0">
                  <Form.Label htmlFor="inputSesso" className="text-lg">
                    Sesso:
                  </Form.Label>
                  <Form.Select
                    label="Sesso"
                    variant="outlined"
                    value={sesso}
                    onChange={(e) => {
                      setSesso(e.target.value);
                    }}
                  >
                    <option key="0" value="-">
                      -
                    </option>
                    <option key="1" value="F">
                      F
                    </option>
                    ;
                    <option key="2" value="M">
                      M
                    </option>
                    ;
                  </Form.Select>
                </div>
              </div>
            </div>
            <div className="flex max-md:flex-col mx-1">
              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <Form.Label
                    htmlFor="inputIndirizzoResidenza"
                    className="text-lg"
                  >
                    Indirzzo di residenza:
                  </Form.Label>
                  <Form.Control
                    defaultValue={indirizzoResidenza}
                    type="text"
                    id="inputIndirizzoResidenza"
                    onChange={(e) => {
                      setIndirizzoResidenza(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <Form.Label htmlFor="inputCittaResidenza" className="text-lg">
                    Citta di residenza :
                  </Form.Label>
                  <Form.Control
                    defaultValue={cittaResidenza}
                    type="text"
                    id="inputCittaResidenza"
                    onChange={(e) => {
                      setCittaResidenza(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <Form.Label htmlFor="inputCap" className="text-lg">
                    Cap :
                  </Form.Label>
                  <Form.Control
                    defaultValue={cap}
                    type="text"
                    id="inputCap"
                    onChange={(e) => {
                      setCap(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <Form.Label htmlFor="inputcf" className="text-lg">
                    Codice fiscale:
                  </Form.Label>
                  <Form.Control
                    defaultValue={cf}
                    type="text"
                    id="inputcf"
                    onChange={(e) => {
                      setCf(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex max-md:flex-col mx-1">
              <div className="flex flex-col basis-1/4 p-2">
                <div className="px-0">
                  <Form.Label
                    htmlFor="inputTipoRegistrazione"
                    className="text-lg"
                  >
                    Tipo registrazione:
                  </Form.Label>
                  <Form.Select
                    label="Tipo registrazione"
                    variant="outlined"
                    value={tipoReg}
                    onChange={(e) => {
                      setTipoReg(e.target.value);
                    }}
                  >
                    <option key="0" value="-">
                      -
                    </option>
                    <option key="1" value="daApp">
                      Da app
                    </option>
                    <option key="2" value="daStudio">
                      Da studio
                    </option>
                    ;
                  </Form.Select>
                </div>
              </div>
              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <Form.Label htmlFor="inputStudio" className="text-lg">
                    Studio:
                  </Form.Label>
                  <Form.Control
                    defaultValue={studio}
                    type="text"
                    id="inputStudio"
                    onChange={(e) => {
                      setStudio(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col basis-1/4 p-2">
                <div className="px-0">
                  <Form.Label htmlFor="inputAbbonato" className="text-lg">
                    Abbonato:
                  </Form.Label>
                  <Form.Select
                    label="Abbonato"
                    variant="outlined"
                    value={abbonato}
                    onChange={(e) => {
                      setAbbonato(e.target.value);
                    }}
                  >
                    <option key="0" value="-">
                      -
                    </option>
                    <option key="1" value="S">
                      Si
                    </option>
                    ;
                    <option key="2" value="N">
                      No
                    </option>
                    ;
                  </Form.Select>
                </div>
              </div>
              <div className="flex flex-col basis-1/4 p-2">
                <div className="px-0">
                  <Form.Label htmlFor="inputTipoPasto" className="text-lg">
                    Tipo pasto:
                  </Form.Label>
                  <Form.Select
                    label="Tipo pasto"
                    variant="outlined"
                    value={tipoPasto}
                    onChange={(e) => {
                      setTipoPasto(e.target.value);
                    }}
                  >
                    <option key="0" value="-">
                      -
                    </option>
                    <option key="1" value="REGOLARE">
                      Regolare
                    </option>
                  </Form.Select>
                </div>
              </div>
            </div>{" "}
            <div className="flex max-md:flex-col mx-1">
              <div className="flex flex-col basis-1/4 p-2">
                <div className="px-0">
                  <Form.Label htmlFor="inputAttivo" className="text-lg">
                    Attivo:
                  </Form.Label>
                  <Form.Select
                    label="Attivo"
                    variant="outlined"
                    value={attivo}
                    onChange={(e) => {
                      setAttivo(e.target.value);
                    }}
                  >
                    <option key="0" value="-">
                      -
                    </option>
                    <option key="1" value="1">
                      Si
                    </option>
                    ;
                    <option key="2" value="0">
                      No
                    </option>
                    ;
                  </Form.Select>
                </div>
              </div>
            </div>
            {isUpdate && (
              <div className="pb-2">
                <p className="m-2 text-xl">Dieta associata all'utente</p>
                <div className="flex justify-between">
                  {dieta.id_dieta != null ? (
                    <p
                      className="m-2 underline text-sky-600"
                      onClick={() => navigate("/diets/read/" + dieta.id_dieta)}
                    >
                      {dieta.tipo}
                    </p>
                  ) : (
                    <p className="m-2 ">Nessuna dieta associata!</p>
                  )}
                  <Button className="mr-4" onClick={handleOpen}>
                    {dieta.id_dieta != null
                      ? "Cambia dieta"
                      : "Associa una dieta"}
                  </Button>
                </div>
              </div>
            )}
            {storicoDiete.length > 0 ? (
              <div className="pb-3">
                <p className="m-2 text-xl">Storico diete</p>
                <div className="flex flex-col">
                  {storicoDiete.map((dieta) => {
                    return (
                      <div className="m-2">
                        <p>{dieta.tipo}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            <div className="h-12"></div>
          </div>
        </Loading>
      </div>
      <div className="absolute inset-x-0 bottom-0 flex justify-center mb-2">
        <Button className="mb-1" onClick={onSave}>
          {isUpdate ? "Salva modifiche" : "Crea utente"}
        </Button>
      </div>
    </div>
  );
};

export default UserCU;
