import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { useParams } from "react-router-dom";

import GiornoRO from "../../components/GiornoRO";
import { Loading, useRequest } from "../../utils";

const DietRead = () => {
  const req = useRequest();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [dieta, setDieta] = useState({});
  const [giornoSelezionato, setGiornoSelezionato] = useState();

  const [pastiPerGiorno, setPastiPerGiorno] = useState([]);
  const [alimentiPerPasti, setAlimentiPerPasti] = useState([]);

  useEffect(() => {
    getDieta();
  }, []);

  useEffect(() => {
    changePastiPerGiorno();
  }, [giornoSelezionato]);

  const getDieta = async () => {
    let res = await req.get(`diet/${id}`);
    console.log("RESULT GET DIETA:");
    console.log(res);
    delete res.success;
    delete res.error;
    delete res.message;

    setDieta(res.data);
    if (res.data.giorni != null) {
      if (res.data.giorni.length > 0) {
        if (res.data.giorni[0].giorno == null) {
          setGiornoSelezionato("0");
        } else {
          setGiornoSelezionato("lun");
        }
      }
    }
    setLoading(false);
  };
  const changePastiPerGiorno = () => {
    console.log(dieta.giorni);

    if (dieta.giorni != null) {
      if (dieta.giorni.length > 0) {
        if (dieta.giorni[0].giorno == null) {
          var newPastiPerGiorno = dieta.giorni[0].pasti.map((pasto) => {
            return { nome: pasto.cat };
          });
          var newAlimentiPerPasti = dieta.giorni[0].pasti;
        } else {
          var newPastiPerGiorno = dieta.giorni.find((giorno) => {
            return giorno.giorno == giornoSelezionato;
          });
          if (newPastiPerGiorno != null) {
            var newAlimentiPerPasti = newPastiPerGiorno.pasti;
            newPastiPerGiorno = newPastiPerGiorno.pasti.map((pasto) => {
              return { nome: pasto.cat };
            });
          }
        }
        setAlimentiPerPasti(newAlimentiPerPasti);
        setPastiPerGiorno(newPastiPerGiorno);
        return;
      }
    }
    newPastiPerGiorno = [];
    setAlimentiPerPasti([]);
    setPastiPerGiorno([]);
  };

  return (
    <div className="flex flex-col items-center w-full h-full p-1 overflow-y-scroll">
      <Loading className="my-auto" loading={loading}>
        <div id="header" className="flex flex-col w-full ">
          <h1 className="text-[#802727] text-4xl">{dieta.tipo}</h1>
        </div>
        <div className="w-full pt-3">
          {(dieta.noteAlimentazione != null && dieta.noteAlimentazione != "") ||
          (dieta.consigli != null && dieta.consigli != "") ? (
            <div className="flex flex-col mx-1">
              <div className="flex flex-col basis-1/4 p-2">
                {dieta.noteAlimentazione != null &&
                dieta.noteAlimentazione != "" ? (
                  <div className="pb-2">
                    <h4 className="text-lg">Note Alimentazione:</h4>
                    <h4 className="text-[#802727]">{dieta.noteAlimentazione}</h4>
                  </div>
                ) : null}
              </div>
              {dieta.consigli != null && dieta.consigli != "" ? (
                <div className="flex flex-col basis-1/2 p-2">
                  <h4 className="text-lg">Consigli:</h4>
                  <h4 className="text-[#802727]">{dieta.consigli}</h4>
                </div>
              ) : null}
            </div>
          ) : null}
          {dieta.monogiorno == 1 ? null : (
            <div className="p-1">
              <Nav
                justify
                variant="tabs"
                defaultActiveKey="lun"
                onSelect={(selectedKey) => {
                  setGiornoSelezionato(selectedKey);
                }}
              >
                <Nav.Item className="m-1">
                  <Nav.Link eventKey="lun">Lunedi</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-1">
                  <Nav.Link eventKey="mar">Martedi</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-1">
                  <Nav.Link eventKey="mer">Mercoledi</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-1">
                  <Nav.Link eventKey="gio">Giovedi</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-1">
                  <Nav.Link eventKey="ven">Venerdi</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-1">
                  <Nav.Link eventKey="sab">Sabato</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-1">
                  <Nav.Link eventKey="dom">Domenica</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          )}
          <GiornoRO
            giorno={giornoSelezionato}
            pasti={pastiPerGiorno || []}
            readOnly={true}
            alimentiPerPasti={alimentiPerPasti}
          ></GiornoRO>
        </div>
      </Loading>
    </div>
  );
};

export default DietRead;
