import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Pasto from "./Pasto";

const GiornoRO = (props) => {
  const giorno = props.giorno;
  const pasti = props.pasti;
  const alimentiPerPasti = props.alimentiPerPasti||[];
  const [readOnly, setReadOnly] = useState(props.readOnly || true);
  const [pastoSelezionato, setPastoSelezionato] = useState();
  const [alimentiSelezionati, setAlimentiSelezionati] = useState(null);

  useEffect(() => {
    changeAlimentiSelezionati();
  }, [pastoSelezionato]);
  useEffect(() => {
    setPastoSelezionato(giorno);
    changeAlimentiSelezionati();
  }, [giorno]);

  const changeAlimentiSelezionati = () => {
    var pastoSel =
      pastoSelezionato != null ? pastoSelezionato.split("_")[0] : "";
    var newAlmentiSelezionati = alimentiPerPasti.filter((alimenti) => {
      return alimenti.cat == pastoSel;
    });
    if (newAlmentiSelezionati.length > 0) {
      setAlimentiSelezionati(newAlmentiSelezionati[0]);
    } else setAlimentiSelezionati(null);
  };
  return (
    <div className="flex flex-col items-center w-full h-full px-2" key={giorno}>
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-evenly mx-1 mb-2">
          <Form.Label className="flex basis-1/4 text-xl">
            Pasto selezionato:
          </Form.Label>
          <Form.Select
            placeholder="Seleziona un pasto"
            name="id_categoria"
            id="id_categoria"
            className="flex "
            defaultValue={pastoSelezionato}
            onChange={(e) => {
              var pastoSel = pasti.find((pasto) => {
                return pasto.nome == e.target.value;
              });
              if (pastoSel != null) {
                setPastoSelezionato(pastoSel.nome + "_" + giorno);
              } else {
                setPastoSelezionato(giorno);
              }
            }}
          >
            <option value={giorno} key={-1}>
              Seleziona un pasto
            </option>
            {pasti.map((pasto, index) => {
              return (
                <option value={pasto.nome} key={index + giorno}>
                  {pasto.nome}
                </option>
              );
            })}
          </Form.Select>
        </div>
      </div>

      <Pasto
        alimenti={alimentiSelezionati}
        pasto={pastoSelezionato}
        readOnly={readOnly}
      ></Pasto>
    </div>
  );
};

export default GiornoRO;
