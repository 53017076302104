import React, { useEffect, useState } from "react";
import {
  FaAppleAlt,
  FaBell,
  FaBlender,
  FaComment,
  FaFile,
  FaHome,
  FaLock,
  FaReceipt,
  FaUsers,
} from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import { useRequest } from "../utils";

const parseJwt = (token) => {
  if (token == null) return { ruolo: "" };
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const NavBar = ({ child }) => {
  const req = useRequest();
  const { id } = useParams();
  const role = parseJwt(localStorage.getItem("accessToken")).ruolo || "";
  const location = useLocation();
  const [notification, setNotifications] = useState(0);

  const checkMessageToRead = async () => {
    let res = await req.get(`/message/checkNotificationMessage`);
    console.log("RESULT GET Notification:");
    console.log(res);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    if (res.data.data.length > 0)
      setNotifications(res.data.data[0].unread_message);
  };

  useEffect(() => {
    checkMessageToRead();
    const interval = setInterval(() => {
      checkMessageToRead();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // 1 minute = 60000 milliseconds-

  return (
    /* ### Add justify-evenly to center item vertically in the navbar */
    <div className="pl-2 bg-secondary mb-2 flex ">
      <div className="flex rounded-tl-2xl rounded-bl-2xl flex-col w-20 h-fit  bg-primary items-center text-center justify-start p-1 mr-2  ">
        <Link to="/homepage" title="Home">
          <div className="mb-4 justify-center items-center mt-4">
            <div className="w-fit  border-[#802727] border-2 rounded p-2">
              <FaHome size={25} color="#802727" />
            </div>
            <div className="text-[#802727] text-base text-center">Home</div>
          </div>
        </Link>
        <Link to="/homeuserslist" title="Home">
          <div className="mb-4 justify-center items-center ">
            <div className="w-fit  border-[#802727] border-2 rounded p-2">
              <FaUsers size={25} color="#802727" />
            </div>
            <div className="text-[#802727] text-base text-center">Clienti</div>
          </div>
        </Link>
        <Link to="/homedietslist" title="Diete">
          <div className="mb-4 justify-center items-center ">
            <div className="w-fit  border-[#802727] border-2 rounded p-2">
              <FaBlender size={25} color="#802727" />
            </div>
            <div className="text-[#802727] text-base text-center">Diete</div>
          </div>
        </Link>
        <Link to="/homealimentilist" title="Alimenti">
          <div className="mb-0 justify-center items-center flex  text-center ">
            <div className="w-fit  border-[#802727] border-2 rounded p-2 flex">
              <FaAppleAlt size={25} color="#802727" />
            </div>
          </div>
          <div className="mb-4 text-[#802727] text-base text-center flex">
            Alimenti
          </div>
        </Link>
        <Link to="/homerecipesist" title="Ricette">
          <div className="mb-4 justify-center items-center ">
            <div className="w-fit  border-[#802727] border-2 rounded p-2">
              <FaReceipt size={25} color="#802727" />
            </div>
            <div className="text-[#802727] text-base text-center">Ricette</div>
          </div>
        </Link>
        <Link to="/homechatlist" title="Chat">
          <div
            className="mb-4 justify-center items-center "
            onClick={() => setNotifications(0)}
          >
            <div
              className="w-fit  border-[#802727] border-2 rounded p-2"
              style={{
                textDecoration: "none",
                position: "relative",
                display: "inline-block",
                borderRadius: "2px",
              }}
            >
              {notification > 0 && (
                <span
                  bg="danger"
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: " -10px",
                    padding: " 4px 8px",
                    borderRadius: "50%",
                    background: "white",
                    color: "#802727",
                  }}
                >
                  {notification}
                </span>
              )}
              {}
              <FaComment size={25} color="#802727" />
            </div>
            <div className="text-[#802727] text-base text-center">Chat</div>
          </div>
        </Link>
        <Link to="/homenotifiche" title="Notifiche">
          <div className="mb-0 justify-center items-center flex  text-center ">
            <div className="w-fit  border-[#802727] border-2 rounded p-2 flex">
              <FaBell size={25} color="#802727" />
            </div>
          </div>
          <div className="mb-4 text-[#802727] text-base text-center flex">
            Notifiche
          </div>
        </Link>
        <Link
          to="/terminiecondizioni" /* "https://www.iubenda.com/termini-e-condizioni/45803042" */
          title="Termini e condizioni"
        >
          <div className="mb-4 justify-center items-center ">
            <div className="w-fit  border-[#802727] border-2 rounded p-2">
              <FaFile size={25} color="#802727" />
            </div>
            <div className="text-[#802727] text-base text-center">Termini</div>
          </div>
        </Link>
        <Link
          to= "privacypage"/* "https://www.iubenda.com/privacy-policy/45803042" */
          title="Privacy"
        >
          <div className="mb-4 justify-center items-center ">
            <div className="w-fit  border-[#802727] border-2 rounded p-2">
              <FaLock size={25} color="#802727" />
            </div>
            <div className="text-[#802727] text-base text-center">Privacy</div>
          </div>
        </Link>
      </div>
      {child}
    </div>
  );
};
