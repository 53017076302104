import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { Loading, useRequest } from "../../utils";

const RecipesRead = () => {
  const req = useRequest();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [ricetta, setRicetta] = useState({});

  useEffect(() => {
    getricetta();
  }, []);

  const getricetta = async () => {
    let res = await req.get(`/recipe/${id}`);
    console.log("RESULT GET ricetta:");
    console.log(res);
    delete res.success;
    delete res.error;
    delete res.message;
    setRicetta(res.data);
    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center w-full h-full p-1  m-3">
      <Loading className="my-auto" loading={loading}>
        <div id="header" className="flex flex-col w-full ">
          <h1 className="text-[#802727] text-4xl">
            {ricetta.nome} di {ricetta.ricAlimento}
          </h1>
        </div>
        <div className="w-full h-full">
          <div className=" my-4 flex flex-col justify-start">
            <p className="my-1">Procedimento:</p>
            <p>{ricetta.descrizione}</p>
          </div>
          {ricetta.ingredienti != null && ricetta.ingredienti.length > 0 ? (
            <div className="my-4 flex flex-col justify-start">
              <p>Ingredienti:</p>

              <div className="mb-4 grid grid-cols-2 max-md:grid-cols-2 max-sm:grid-cols-1 grid-rows-1 gap-1 w-full px-2 ">
                {ricetta.ingredienti.map((ingr) => {
                  return (
                    <div className="mx-6 my-2" key={ingr.id_ingrediente}>
                      <Card>
                        <Card.Body>
                          <div className="flex flex-col items-center ">
                            <div className="mx-3">{ingr.nome}</div>
                            <div className="flex">
                              <p className="mx-2">Dosaggio: </p>

                              <div className="mx-1">{ingr.dosaggio}</div>
                              <div className="mx-1">{ingr.unitaMisura}</div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
        <div className="w-full"></div>
      </Loading>
    </div>
  );
};

export default RecipesRead;
