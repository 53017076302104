import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import { useRequest } from "../utils";

const parseJwt = (token) => {
  
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const HomePage = () => {
  const [data, setData] = useState([]);
  let token = localStorage.getItem("accessToken");
  const decodedJwt = parseJwt(token);
  console.log("decoded jwt");
  console.log(decodedJwt);
  useEffect(() => {
    return () => {
      setData([]);
    };
  }, []);

  const data_table = useMemo(() => data, [data]);
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "idUtente",
      },
      {
        Header: "Nome",
        accessor: "nome",
      },
      {
        Header: "Cognome",
        accessor: "cognome",
      },
      {
        Header: "Email",
        accessor: "email",
      },
    ],
    []
  );
  const tableInstance = useTable(
    { columns, data },
    useFilters,
    useGlobalFilter,
    useSortBy
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    visibleColumns,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
  } = tableInstance;
  return (
    <div className="flex rounded-tr-2xl rounded-br-2xl flex-col w-full h-full bg-secondary bg-opacity-60 p-2">
      <div id="header" className="flex flex-col w-full mb-4">
        <h1 className="text-[#802727] text-5xl">
          Benvenuto nel pannello di gestione
        </h1>
      </div>
      <div id="tabs" className="w-full ">
       { /*<div className="flex flex-row items-center justify-end mr-4 mb-6">
          <div className="flex col-span-1  flex-col justify-center items-center container overflow-hidden align-middle mt-3">
            <h2 className="text-[#802727] text-4xl">
              Edoardo Banchi nutrizionista
            </h2>
          </div>
  </div>*/}
        <div className="items-center flex justify-center">
          <img alt="img-blur-shadow" className="w-8/12	" src={"/home2.jpg"} />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
