import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Accordion, Alert, Form, Toast, ToastContainer } from "react-bootstrap";

import { useParams } from "react-router-dom";

import moment from "moment";
import "moment/locale/it";
import { Loading, useRequest } from "../../utils";
import { Visita } from "../../components/Visita";

const UserStats = () => {
  const req = useRequest();
  const { id, name, cognome } = useParams();
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filter, setFilter] = useState([
    "passi",
    "acqua",
    "orina",
    "feci",
    "peso",
    "ciclo",
    "visite",
  ]);
  const [data, setData] = useState([]);
  const localizer = momentLocalizer(moment);
  const [visite, setVisite] = useState([]);
  const [nextVisite, setNextVisite] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const goFilter = (filterTmp) => {
    let tmpFiltered = data.filter((data) => {
      return filterTmp.includes(data.type);
    });
    setFilteredItems(tmpFiltered);
  };

  const changeFilter = (filterName) => {
    var filterTmp = filter;
    if (filterTmp.findIndex((ev) => ev == filterName) != -1) {
      filterTmp = filterTmp.filter((ev) => ev != filterName);
    } else {
      filterTmp.push(filterName);
    }
    setFilter(filterTmp);
    goFilter(filterTmp);
  };

  const checkFilter = (filterName) => {
    return filter.findIndex((ev) => ev == filterName) != -1;
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filter.length == 6) {
        setFilter([]);
        setFilteredItems();
      } else {
        setFilter([
          "passi",
          "acqua",
          "orina",
          "feci",
          "peso",
          "ciclo",
          "visite",
        ]);
        setFilteredItems(data);
      }
    };
    return (
      <div className="flex justify-evenly w-100">
        <div className="text-center flex ">
          <div className="block p-2">
            <div key={`default-checkbox`} className="m-1">
              <Form.Check
                type="checkbox"
                id="default-checkbox"
                defaultChecked={checkFilter("passi")}
                onChange={() => {
                  changeFilter("passi");
                }}
              />
            </div>
          </div>
          <div className="p-2 flex flex-1 grow justify-center items-center text-xl">
            Passi
          </div>
        </div>
        <div className="text-center flex ">
          <div className="block p-2">
            <div key={`default-checkbox`} className="m-1">
              <Form.Check
                type="checkbox"
                id="default-checkbox"
                defaultChecked={checkFilter("acqua")}
                onChange={() => {
                  changeFilter("acqua");
                }}
              />
            </div>
          </div>
          <div className="p-2 flex flex-1 grow justify-center items-center text-xl">
            Acqua
          </div>
        </div>
        <div className="text-center flex ">
          <div className="block p-2">
            <div key={`default-checkbox`} className="m-1">
              <Form.Check
                type="checkbox"
                id="default-checkbox"
                defaultChecked={checkFilter("orina")}
                onChange={() => {
                  changeFilter("orina");
                }}
              />
            </div>
          </div>
          <div className="p-2 flex flex-1 grow justify-center items-center text-xl">
            Orina
          </div>
        </div>
        <div className="text-center flex ">
          <div className="block p-2">
            <div key={`default-checkbox`} className="m-1">
              <Form.Check
                type="checkbox"
                id="default-checkbox"
                defaultChecked={checkFilter("feci")}
                onChange={() => {
                  changeFilter("feci");
                }}
              />
            </div>
          </div>
          <div className="p-2 flex flex-1 grow justify-center items-center text-xl">
            Feci
          </div>
        </div>
        <div className="text-center flex ">
          <div className="block p-2">
            <div key={`default-checkbox`} className="m-1">
              <Form.Check
                type="checkbox"
                id="default-checkbox"
                defaultChecked={checkFilter("peso")}
                onChange={() => {
                  changeFilter("peso");
                }}
              />
            </div>
          </div>
          <div className="p-2 flex flex-1 grow justify-center items-center text-xl">
            Peso
          </div>
        </div>
        <div className="text-center flex ">
          <div className="block p-2">
            <div key={`default-checkbox`} className="m-1">
              <Form.Check
                type="checkbox"
                id="default-checkbox"
                defaultChecked={checkFilter("ciclo")}
                onChange={() => {
                  changeFilter("ciclo");
                }}
              />
            </div>
          </div>
          <div className="p-2 flex flex-1 grow justify-center items-center text-xl">
            Ciclo
          </div>
        </div>
        <div className="text-center flex ">
          <div className="block p-2">
            <div key={`default-checkbox`} className="m-1">
              <Form.Check
                type="checkbox"
                id="default-checkbox"
                defaultChecked={checkFilter("visite")}
                onChange={() => {
                  changeFilter("visite");
                }}
              />
            </div>
          </div>
          <div className="p-2 flex flex-1 grow justify-center items-center text-xl">
            Visite
          </div>
        </div>
      </div>
    );
  }, [filter]);

  const fetchData = async () => {
    let res = await req.get(`daySteps/${id}`);
    delete res.success;
    delete res.error;
    delete res.message;

    let data = [];

    res.data.data.map((el) => {
      data.push({
        title: el.passi + " passi",
        start: Date.parse(el.dataAgg),
        end: Date.parse(el.dataAgg),
        type: "passi",
        color: "green",
      });
    });

    let res2 = await req.get(`dayWater/${id}`);
    res2.data.data.map((el) => {
      data.push({
        title: el.nrBicchieri + " bicchieri d'acqua",
        start: Date.parse(el.dataAgg),
        end: Date.parse(el.dataAgg),
        type: "acqua",
        color: "blue",
      });
    });

    let res3 = await req.get(`dayOrina/${id}`);
    res3.data.data.map((el) => {
      data.push({
        title: "Colore orina: " + el.colore,
        start: Date.parse(el.dataAgg),
        end: Date.parse(el.dataAgg),
        type: "orina",
        color: "#cccc00",
      });
    });

    let res4 = await req.get(`dayFeci/${id}`);
    res4.data.data.map((el) => {
      data.push({
        title: "Feci di tipo " + el.tipo,
        start: Date.parse(el.dataAgg),
        end: Date.parse(el.dataAgg),
        type: "feci",
        color: "brown",
      });
    });

    let res5 = await req.get(`dayWeight/${id}`);
    res5.data.data.map((el) => {
      data.push({
        title: "Peso: " + el.valore + " kg",
        start: Date.parse(el.dataAgg),
        end: Date.parse(el.dataAgg),
        type: "peso",
        color: "#00ccff",
      });
    });
    let res6 = await req.get(`dayCiclo/${id}`);
    res6.data.data.map((el) => {
      data.push({
        title: "Ciclo: " + el.tipo,
        start: Date.parse(el.dataAgg),
        end: Date.parse(el.dataAgg),
        type: "ciclo",
        color: "red",
      });
    });
    let res7 = await req.get(`dayVisit/${id}`);

    res7.data.data.map((el) => {
      data.push({
        title: "Tipo visita: " + el.tipo,
        start: Date.parse(el.dataVisita),
        end: Date.parse(el.dataVisita),
        type: "visite",
        color: "#00ccaf",
      });
    });
    res7.data.data.forEach((visita) => {
      visita.dataVisita = new Date(visita.dataVisita);
    });
    const today = Date.now();
    setVisite(
      res7.data.data.filter((visita) => {
        return visita.dataVisita < today;
      })
    );
    setNextVisite(
      res7.data.data.filter((visita) => {
        return visita.dataVisita >= today;
      })
    );
    setLoading(false);
    setData(data);
    setFilteredItems(data);
  };

  return (
    <div
      className="flex flex-col items-center w-full p-1 "
      style={{ height: "95%" }}
    >
      <ToastContainer className="p-3" position="top-end">
        <Toast
          bg="danger"
          delay={3000}
          autohide
          onClose={() => setShowAlert(false)}
          show={showAlert}
        >
          <Toast.Header>Attenzione!</Toast.Header>
          <p className="m-2">"{alertMessage}</p>
          <hr />
        </Toast>
      </ToastContainer>
      <Loading className="  my-auto" loading={loading}>
        <div id="header" className="flex w-full justify-between">
          <h1 className="text-[#802727] text-4xl">
            Statistiche per l'utente {name ?? ""} {cognome ?? ""}
          </h1>
        </div>
        <div className="w-100"> {subHeaderComponentMemo}</div>
        <div className="w-full mt-4">
          <Calendar
            localizer={localizer}
            events={data.filter((data) => {
              return filter.includes(data.type);
            })}
            popup={true}
            selectable
            style={{
              height: 660,
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            views={["month"]}
            messages={{
              next: "Avanti",
              previous: "Indietro",
              today: "Oggi",
              more: "Altri",
            }}
            eventPropGetter={(event, start, end, isSelected) => ({
              style: {
                backgroundColor: event.color,
              },
            })}
          />

          <div className="mt-3">
            <Accordion>
              {nextVisite.length > 0 && (
                <Accordion.Item eventKey="1" className="p-2">
                  <Accordion.Header>Prossima visita</Accordion.Header>
                  <Accordion.Body>
                    {nextVisite.map((visita) => {
                      return <Visita visita={visita} key={visita.id} />;
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              )}
              {visite.length > 0 && (
                <Accordion.Item eventKey="0" className="p-2">
                  <Accordion.Header>Storico Visite</Accordion.Header>
                  <Accordion.Body>
                    {visite.map((visita) => {
                      return (
                        <div key={visita.id}>
                          <Visita visita={visita} />
                          <hr />
                        </div>
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>
          </div>

          <Alert
            variant="danger"
            dismissible
            onClose={() => setShowAlert(false)}
            show={showAlert}
          >
            <Alert.Heading>Attenzione!</Alert.Heading>
            <p>{alertMessage}</p>
          </Alert>
        </div>
      </Loading>
    </div>
  );
};

export default UserStats;
