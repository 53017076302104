import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useRequest } from "../../utils";

const AlimentsCU = (props) => {
  const req = useRequest();
  var id = props.id;
  const isUpdate = id != "-1";
  const [alimento, setAlimento] = useState();
  const [nomeAlimento, setNomeAlimento] = useState();
  const [iconaAlimento, setIconaAlimento] = useState();
  const allIcon = [
    "cheeseIcon",
    "chickenIcon",
    "eggIcon",
    "fishIcon",
    "fruitIcon",
    "meatIcon",
    "oilIcon",
    "podsIcon",
    "slicedIcon",
    "teaIcon",
    "vegetableIcon",
    "yogurtIcon",
  ];

  useEffect(() => {
    setNomeAlimento(null);
    setIconaAlimento(null);
    if (isUpdate) getAimento();
  }, [id]);

  useEffect(() => {
    if (!isUpdate) {
      setNomeAlimento(null);
      setIconaAlimento(null);
    }
  }, []);

  const getAimento = async () => {
    let res = await req.get(`/alimento/${id}`);
    console.log("RESULT ALIMENTO:");
    console.log(res);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    setAlimento(res.data);
    setNomeAlimento(res.data.nome);
  
    if (res.data.icona != null) {
      var iconAlimento = allIcon.findIndex((icon) => {
        return icon == res.data.icona;
      });
      setIconaAlimento(iconAlimento);
    }
  };

  const onSave = async () => {
    var alimentoToSave=alimento??{}
    alimentoToSave.nome = nomeAlimento;
    alimentoToSave.icona = allIcon[iconaAlimento];
    //alimento.icona=iconaAlimento;
    var res;
    if (isUpdate) {
      res = await req.put(`/alimento`, {
        //TODO aggiungere id_utente alla chiamata
        id_utenteApp: localStorage.getItem("idUtente"),
        alimento: alimentoToSave,
      });
    } else {
      res = await req.post(`/alimento`, {
        //TODO aggiungere id_utente alla chiamata
        id_utenteApp: localStorage.getItem("idUtente"),
        alimento: alimentoToSave,
      });
    }
    var idAlimento = res.idAlimento;
    console.log(res);
    if (idAlimento) {
      props.handleClose(true);
    } else props.handleClose(true);
  };

  return (
    <>
      <Offcanvas show={props.show} key={id} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {isUpdate ? "Modifica alimento" : "Crea nuovo alimento"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="flex flex-col">
            <div className="pb-2">
              <Form.Label htmlFor="inputnome"> Nome : </Form.Label>
              <Form.Control
                defaultValue={nomeAlimento}
                type="text"
                id="inputnome"
                onChange={(e) => {
                  setNomeAlimento(e.target.value);
                }}
              />
            </div>
            <div className="pb-2">
              <Form.Label htmlFor="inputnome"> Immagine : </Form.Label>
              <div className="mb-4 grid grid-cols-4 max-md:grid-cols-2 max-sm:grid-cols-1 grid-rows-1 gap-1 w-full p-2 ">
                {allIcon.map((icon, index) => (
                  <div key={index}>
                  <img
                    onClick={() => {
                      setIconaAlimento(index);
                    }}
                    alt="img-blur-shadow"
                    className={
                      iconaAlimento == index
                        ? "m-2 w-14 h-14 border-2 border-red-900 rounded-md hoover: cursor-pointer"
                        : "m-2 w-14 h-14 hoover: cursor-pointer"
                    }
                    src={"/alimenti/" + icon + ".png"}
                  />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-evenly">
              <Button
                onClick={() => {
                  onSave();
                }}
              >
                {isUpdate ? "Modifica alimento" : "Crea alimento"}
              </Button>
              <Button onClick={() => props.handleClose(false)}>Annulla</Button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AlimentsCU;
