import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Form,
  Nav,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Giorno from "../../components/Giorno";

import { Loading, useRequest } from "../../utils";

const DietsCU = () => {
  const req = useRequest();
  const { id, name } = useParams();
  const isUpdate = id != null;
  const [loading, setLoading] = useState(true);
  const [nomeDieta, setNomeDieta] = useState("");
  const [noteAlimentazione, setNoteAlimentazione] = useState("");
  const [consigli, setConsigli] = useState("");
  const [alimenti, setAlimenti] = useState([]);
  const [giornoSelezionato, setGiornoSelezionato] = useState("0");
  const [pasti, setPasti] = useState([]);
  const [unitaMisura, setUnitaMisura] = useState([]);
  const [multiGiorno, setMultiGiorno] = useState(false);
  const [giorni, setGiorni] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      if (isUpdate) {
        await fetchDieta();
      }
      await getAlimenti();
      await getPasti();
      await getUnitaMisura();

      setLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setIdUnitaMisura();
  }, [unitaMisura]);

  const fetchDieta = async () => {
    let res = await req.get(`diet/${id}`);
    console.log("RESULT GET DIETA:");
    console.log(res);
    delete res.success;
    delete res.error;
    delete res.message;
    setNomeDieta(res.data.tipo);
    setConsigli(res.data.consigli);
    setNoteAlimentazione(res.data.noteAlimentazione);
    setMultiGiorno(res.data.monogiorno == 0);
    if (res.data.monogiorno) {
      if (res.data.giorni.length > 0) {
        res.data.giorni[0].giorno = "0";
      }
      setGiornoSelezionato("0");
    } else {
      setGiornoSelezionato("lun");
    }
    setGiorni(res.data.giorni);
  };

  const setIdUnitaMisura = () => {
    giorni.forEach((giorno) => {
      giorno.pasti.forEach((pasto) => {
        pasto.alimenti.forEach((alimento) => {
          alimento.alternative.forEach((alternativa) => {
            alternativa.id_unitaMisura1 =
              alternativa.unitaMisura1 != null
                ? unitaMisura.find(
                    (unita) => unita.nome == alternativa.unitaMisura1
                  ) != null
                  ? unitaMisura.find(
                      (unita) => unita.nome == alternativa.unitaMisura1
                    ).id_unitaMisura
                  : null
                : null;
          });
          alimento.id_unitaMisura1 =
            alimento.unitaMisura1 != null
              ? unitaMisura.find(
                  (unita) => unita.nome == alimento.unitaMisura1
                ) != null
                ? unitaMisura.find(
                    (unita) => unita.nome == alimento.unitaMisura1
                  ).id_unitaMisura
                : null
              : null;
        });
      });
    });
  };
  const getAlimenti = async () => {
    let res = await req.get(`/alimento/all`);
    console.log("RESULT ALIMENTI:");
    console.log(res);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    setAlimenti(res.data.data);
  };

  const getPasti = async () => {
    let res = await req.get(`/pasti`);
    console.log("RESULT PASTI:");
    console.log(res);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    setPasti(res.data.data);
  };

  const getUnitaMisura = async () => {
    let res = await req.get(`/unitaMisura`);
    console.log("RESULT UNITA DI MISURA:");
    console.log(res);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    setUnitaMisura(res.data.data);
  };
  const handleGiorni = (giorno, alimentiPerPasti) => {
    var index = giorni.findIndex((el) => {
      return el.giorno == giorno;
    });
    var newGiorni = giorni;
    if (index == -1) {
      newGiorni.push({
        giorno: giorno,
        pasti: alimentiPerPasti,
      });
      setGiorni(newGiorni);
    } else {
      newGiorni[index].pasti = alimentiPerPasti;
      setGiorni(newGiorni);
    }
  };
  const changeMultiGiorno = () => {
    if (multiGiorno) setGiornoSelezionato("0");
    else setGiornoSelezionato("lun");
    setMultiGiorno(!multiGiorno);
  };

  const onSave = async () => {
    setLoading(true);
    setShowAlert(false);
    if (giorni.length > 0) {
      if (multiGiorno) {
        console.log("multigiorno")
        var dieta = {};
        dieta.giorni = giorni.filter((giorno) => giorno.giorno != "0");

        console.log("GIORNI: ", dieta.giorni)

        /* if (dieta.length == 7) {
          dieta.forEach((giorno) => { .    
            if (giorno.alimentiPerPasti.length === pasti.length) {
            } else {
              setAlertMessage(
                "Non hai aggiunto alimenti a tutti i pasti di " + { giorno }
              );
              setShowAlert(true);
            }
          });*/
        dieta.monogiorno = 0;
        await saveDieta(dieta);
        /* } else {
          setAlertMessage(
            "Non hai aggiunto alimenti a tutti i giorni della settimana"
          );
          setShowAlert(true);
          setLoading(false);
        }*/
      } else {
        var dieta = {};
        dieta.giorni = [giorni.find((giorno) => giorno.giorno == "0")];

        /* if (dieta.alimentiPerPasti.length === pasti.length) { */
        dieta.monogiorno = 1;
        await saveDieta(dieta);
        /* } else {
          setAlertMessage(
            "Non hai aggiunto elementi a tutti i pasti della giornata!"
          );
          setShowAlert(true);
        } */
      }
    } else {
      setAlertMessage(
        "Non hai aggiunto elementi a nessun pasto della giornata!"
      );
      setShowAlert(true);
      setLoading(false);
    }
  };

  const formattaDieta = (dieta) => {
    dieta.tipo = nomeDieta;
    dieta.noteAlimentazione = noteAlimentazione;
    dieta.consigli = consigli;
    dieta.giorni.forEach((giorno) => {
      giorno.pasti.forEach((pastoNelGiorno) => {
        if (pastoNelGiorno.id_categoria == null) {
          var newpasto = pasti.find(
            (pasto) => pasto.nome == pastoNelGiorno.cat
          );
          pastoNelGiorno.id_categoria = newpasto.id_categoria;
        }
      });
    });
    return dieta;
  };

  const saveDieta = async (dieta) => {
    dieta = formattaDieta(dieta);
    setShowAlert(false);
    var idDieta;

    if (isUpdate) {
      try {
        dieta.id_dieta = id;
        let { data: res } = await req.put(`/diet`, {
          id_utenteApp: localStorage.getItem("idUtente"),
          dieta: dieta,
        });
        idDieta = 
        res.idDieta;
        console.log(res);
      } catch (e) {
        setLoading(false);
        setShowAlert(true);
        setAlertMessage("Errore nel salvataggio dieta");
      }
    } else {
      // Invio i dati al server
      try {
        let { data: res } = await req.post(`/diet`, {
          //TODO aggiungere id_utente alla chiamata
          id_utenteApp: localStorage.getItem("idUtente"),
          dieta: dieta,
        });
        idDieta = res.idDieta;
        console.log(res);
      } catch (e) {
       
        setLoading(false);
        setShowAlert(true);
        setAlertMessage("Errore nel salvataggio dieta");
      }
    }
    setLoading(false);
    navigate("/diets/read/" + idDieta);
    console.log(dieta);
    return dieta;
  };

  return (
    <div
      className="flex flex-col items-center w-full p-1 "
      style={{ height: "95%" }}
    >
      <ToastContainer className="p-3" position="top-end">
        <Toast
          bg="danger"
          delay={3000}
          autohide
          onClose={() => setShowAlert(false)}
          show={showAlert}
        >
          <Toast.Header>Attenzione!</Toast.Header>
          <p className="m-2">"{alertMessage}</p>
          <hr />
        </Toast>
      </ToastContainer>
      <Loading className="  my-auto" loading={loading}>
        <div id="header" className="flex w-full justify-between">
          <h1 className="text-[#802727] text-4xl">
            {isUpdate ? "Modifica dieta \"" + name + "\"" : "Crea una nuova dieta"}
          </h1>
          <div className="flex justify-center  mx-2">
          <Button className="mt-2 mb-2" onClick={onSave}>
            {isUpdate ? "Salva modifiche" : "Crea dieta"}
          </Button>
        </div>
        </div>
        <div className="w-full">
          <div className="flex max-md:flex-col mx-1">
            <div className="flex flex-col basis-1/4 p-2">
              <div className="pb-2">
                <Form.Label htmlFor="inputnome"> Nome dieta: </Form.Label>
                <Form.Control
                  defaultValue={nomeDieta}
                  type="text"
                  id="inputnome"
                  onChange={(e) => {
                    setNomeDieta(e.target.value);
                  }}
                />
              </div>
              <div className="pt-2">
                <Form>
                  <Form.Check
                    defaultChecked={multiGiorno}
                    type="switch"
                    id="custom-switch"
                    label="Ogni giorno pasti diversi"
                    onChange={() => {
                      changeMultiGiorno();
                    }}
                  />
                </Form>
              </div>
            </div>
            <div className="flex flex-col basis-1/2 p-2">
              <Form.Label htmlFor="inputnoteAlimentazione">
                Note alimentazione:
              </Form.Label>
              <Form.Control
                defaultValue={noteAlimentazione}
                as="textarea"
                rows={3}
                id="inputnoteAlimentazione"
                onChange={(e) => setNoteAlimentazione(e.target.value)}
              />
            </div>

            <div className="flex flex-col basis-1/2 p-2">
              <Form.Label htmlFor="inputConsigli">Consigli: </Form.Label>
              <Form.Control
                defaultValue={consigli}
                as="textarea"
                rows={3}
                id="inputConsigli"
                onChange={(e) => setConsigli(e.target.value)}
              />
            </div>
          </div>
          {multiGiorno ? (
            <div className="p-1">
              <Nav
                justify
                variant="tabs"
                defaultActiveKey="lun"
                onSelect={(selectedKey) => {
                  setGiornoSelezionato(selectedKey);
                }}
              >
                <Nav.Item className="m-1">
                  <Nav.Link eventKey="lun">Lunedi</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-1">
                  <Nav.Link eventKey="mar">Martedi</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-1">
                  <Nav.Link eventKey="mer">Mercoledi</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-1">
                  <Nav.Link eventKey="gio">Giovedi</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-1">
                  <Nav.Link eventKey="ven">Venerdi</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-1">
                  <Nav.Link eventKey="sab">Sabato</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-1">
                  <Nav.Link eventKey="dom">Domenica</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          ) : null}
          <div className="m-1" key={giornoSelezionato}>
            <Giorno
              isUpdate={isUpdate}
              giorno={giornoSelezionato}
              alimenti={alimenti}
              alimentiPerPasti={
                giorni.findIndex((el) => {
                  return el.giorno == giornoSelezionato;
                }) != -1
                  ? giorni.find((el) => {
                      return el.giorno == giornoSelezionato;
                    }).pasti
                  : []
              }
              pasti={pasti}
              unitaMisura={unitaMisura}
              handleGiorni={handleGiorni}
            ></Giorno>
          </div>
          <Alert
            variant="danger"
            dismissible
            onClose={() => setShowAlert(false)}
            show={showAlert}
          >
            <Alert.Heading>Attenzione!</Alert.Heading>
            <p>{alertMessage}</p>
          </Alert>
        </div>
       
      </Loading>
    </div>
  );
};

export default DietsCU;
