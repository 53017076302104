import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import UsersTable from "./users_table.js";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BiErrorAlt } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import { Loading } from "../utils/loading.js";

const HomeUsersList = () => {
  const [selectedFileUtenti, setSelectedFileUtenti] = useState(null);
  const [selectedFileVisite, setSelectedFileVisite] = useState(null);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleFileUploadUtenti = (event) => {
    console.log(event.target.files);
    let tmpFile = event.target.files[0];

    let formData = new FormData();
    var formattedName = tmpFile.name.replace(/\ /g, "_");
    formData.append("file", tmpFile, formattedName);
    setSelectedFileUtenti(formData);
    toast("Upload effettuato", {
      duration: 2000,
    });
  };

  const handleFileUploadVisite = (event) => {
    console.log(event.target.files);
    let tmpFile = event.target.files[0];

    let formData = new FormData();
    var formattedName = tmpFile.name.replace(/\ /g, "_");
    formData.append("file", tmpFile, formattedName);
    setSelectedFileVisite(formData);
    toast("Upload effettuato", {
      duration: 2000,
    });
  };

  const handleFileSubmitUtenti = async (event) => {
    if (selectedFileUtenti == null || selectedFileUtenti == undefined) {
      toast.error("Selezionare un file");
      return;
    }
    try {
      setLoading(true);
      await axios
        .post(
          "https://appedoardobanchinutrizionista.it/api/uploadExcelFile",
          selectedFileUtenti,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          setLoading(false);
          console.log(res);

          if (
            res.data.rowToCheck.length > 0 ||
            res.data.doubleMailCheckDB.length > 0 ||
            res.data.doubleMailToCheckExcel.length > 0
          ) {
            var idToCheck = res.data.rowToCheck.toString();
            var doubleMaildB = res.data.doubleMailCheckDB.toString();
            var doubleMailExcel = res.data.doubleMailToCheckExcel.toString();
            var msg = "";
            if (res.data.rowToCheck.length > 0) {
              msg +=
                "Non è stato possibile aggiornare le righe " +
                idToCheck.replaceAll(",", ", ") +
                " perchè non era presente l'email o l'ID Paziente.\n";
            }
            if (res.data.doubleMailCheckDB.length > 0) {
              msg +=
                "Non è stato possibile aggiornare le righe " +
                doubleMaildB.replaceAll(",", ", ") +
                " perchè la mail è presente piu volte nel datatbase.\n";
            }
            if (res.data.doubleMailToCheckExcel.length > 0) {
              msg +=
                "Non è stato possibile aggiornare le righe " +
                doubleMailExcel.replaceAll(",", ", ") +
                " perchè la mail è presente piu volte nel file .\n";
            }
            msg += "Tutte le altre righe sono state modificate correttamente";
            toast.custom(
              (t) => (
                <div
                  className="bg-white shadow-md rounded-md p-4"
                  style={{ width: "65%" }}
                >
                  <div className="text-lg font-medium">{msg}</div>
                  <div className="flex justify-end">
                    <button
                      className="text-green-500 hover:text-gray-600 transition-colors"
                      onClick={() => toast.dismiss(t.id)}
                    >
                      Chiudi
                    </button>
                  </div>
                </div>
              ),
              {
                sticky: true,
              }
            );
          } else {
            toast("File caricato con successo", {
              duration: 3000,
              icon: <FaCheck color="green" />,
            });
            setSelectedFileUtenti(null);
          }
        });
    } catch (err) {
      setLoading(false);
      if (err.response.data.error == "3") {
        toast(
          "Non è stato possibile trovare il worksheet denominato 'Pazienti'",
          {
            duration: 3000,
            icon: <BiErrorAlt color="red" />,
          }
        );
      } else if (err.response.data.error == "2") {
        toast("La struttura della tabella non è corretta.", {
          duration: 3000,
          icon: <BiErrorAlt color="red" />,
        });
      } else {
        toast("Si è verificato un errore nel caricamento del file", {
          duration: 3000,
          icon: <BiErrorAlt color="red" />,
        });
      }
      console.log(err);
      return;
    }
  };
  const handleFileSubmitVisite = async (event) => {
    if (selectedFileVisite == null || selectedFileVisite == undefined) {
      toast.error("Selezionare un file");
      return;
    }
    try {
      setLoading(true);
      await axios
        .post(
          "https://appedoardobanchinutrizionista.it/api/uploadExcelFileVisite",
          selectedFileVisite,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          setLoading(false);
          console.log(res);

          if (
            res.data.errorAppointment.length > 0 ||
            res.data.errorDate.length > 0 ||
            res.data.alreadyInsert.length > 0 ||
            res.data.errorDb.length > 0
          ) {
            var msg = "";
            if (res.data.errorAppointment.length > 0) {
              var errorAppointment = res.data.errorAppointment.toString();

              msg +=
                "Non è stato possibile aggiornare le righe " +
                errorAppointment.replaceAll(",", ", ") +
                " perchè non era presente l'email o l'ID Paziente.\n";
            }
            if (res.data.errorDate.length > 0) {
              var errorDate = res.data.errorDate.toString();

              msg +=
                "Non è stato possibile aggiornare le righe " +
                errorDate.replaceAll(",", ", ") +
                " perchè la data non era fromattata correttamente.\n";
            }
            if (res.data.alreadyInsert.length > 0) {
              var alreadyInsert = res.data.alreadyInsert.toString();

              msg +=
                "Le righe" +
                alreadyInsert.replaceAll(",", ", ") +
                " erano già presenti nel database.\n";
            }
            if (res.data.errorDb.length > 0) {
              var errorDb = res.data.errorDb.toString();

              msg +=
                "Non è stato possibile aggiornare le righe " +
                errorDb.replaceAll(",", ", ") +
                " perchè non era presente l'utente nel database.\n";
            }
            msg += "Tutte le altre righe sono state modificate correttamente";
            toast.custom(
              (t) => (
                <div
                  className="bg-white shadow-md rounded-md p-4"
                  style={{ width: "65%" }}
                >
                  <div className="text-lg font-medium">{msg}</div>
                  <div className="flex justify-end">
                    <button
                      className="text-green-500 hover:text-gray-600 transition-colors"
                      onClick={() => toast.dismiss(t.id)}
                    >
                      Chiudi
                    </button>
                  </div>
                </div>
              ),
              {
                sticky: true,
              }
            );
          } else {
            toast("File caricato con successo", {
              duration: 3000,
              icon: <FaCheck color="green" />,
            });
          }
          setSelectedFileVisite(null);
        });
    } catch (err) {
      setLoading(false);
      if (err.response.data.error == "3") {
        toast(
          "Non è stato possibile trovare il worksheet denominato 'Visite'",
          {
            duration: 3000,
            icon: <BiErrorAlt color="red" />,
          }
        );
      } else if (err.response.data.error == "2") {
        toast("La struttura della tabella non è corretta.", {
          duration: 3000,
          icon: <BiErrorAlt color="red" />,
        });
      } else {
        toast("Si è verificato un errore nel caricamento del file", {
          duration: 3000,
          icon: <BiErrorAlt color="red" />,
        });
      }
      console.log(err);
      return;
    }
  };

  return (
    <div className="flex rounded-tr-2xl items-center rounded-br-2xl flex-col w-full h-full bg-secondary bg-opacity-60 p-2">
      <Loading className="  my-auto" loading={loading}>
        <div id="header" className="flex flex-col w-full mb-4">
          <Toaster />
          <div className="flex justify-between m-2">
            <h1 className="text-[#802727] text-2xl">Clienti</h1>
            <Button
              className="bg-[#802727] text-white hover:bg-[#802727] text-white"
              onClick={() => navigate("/users/create")}
            >
              Aggiungi un cliente
            </Button>
          </div>
          <UsersTable />
          <hr className="mt-2"></hr>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <h2 className="m-2 text-xl">
                Carica un file per aggiungere o modificare i clienti
              </h2>
              <div className="flex m-2">
                <input
                  type="file"
                  id="upload"
                  name="samplefile"
                  accept=".xls, .xlt, .xla, .xlsx, .xltx, .xlsm, .xltm, .xlam, .xlsb, .numbers"
                  onChange={(e) => handleFileUploadUtenti(e)}
                />

                <Button onClick={handleFileSubmitUtenti}>Carica</Button>
              </div>
            </div>
            <div className="flex flex-col">
              <h2 className="m-2 text-xl">
                Carica un file per aggiungere le visite
              </h2>
              <div className="flex m-2">
                <input
                  type="file"
                  id="upload"
                  name="samplefile"
                  accept=".xls, .xlt, .xla, .xlsx, .xltx, .xlsm, .xltm, .xlam, .xlsb, .numbers"
                  onChange={(e) => handleFileUploadVisite(e)}
                />

                <Button onClick={handleFileSubmitVisite}>Carica</Button>
              </div>
            </div>
          </div>
        </div>
      </Loading>
    </div>
  );
};

export default HomeUsersList;
