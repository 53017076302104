import React from 'react';
import "./style.css";
export const Loading = ({loading, children}) => {
  if (loading) {
    return <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
  }
  return children;
}
