import React, { useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useRequest } from "../utils";

const RecipesTable = () => {
  const req = useRequest();
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const [selectedRicetta, setSelectedRicetta] = useState(-1);
  const [messageDelete, setMessageDelete] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getRecipes();
  }, []);

  const tooltipUpdateRecipe = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Modifica ricetta
    </Tooltip>
  );
  const tooltipDeleteRecipe = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Rimuovi ricetta
    </Tooltip>
  );
  const tooltipReadRecipe = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Visualizza ricetta
    </Tooltip>
  );

  const getRecipes = async () => {
    let { data: res } = await req.get(`/recipe/all`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    setData(res.data);
    setFilteredItems(res.data);
  };
  const removeRicetta = async () => {
    
    let res = await req.delete(`/recipe/${selectedRicetta}`);
    console.log(res);
    if (res.data.success == "0") {
      var text =
        "Non è stato possibile eliminare la ricetta perche è associata ad alcune diete";
      setMessageDelete(text);
    } else {
      delete res.success;
      delete res.error;
      delete res.message;
      handleClose();
      getRecipes();
    }
  };

  const goFilter = async (filter) => {
    let tmpFiltered = data.filter(
      (item) =>
        item.nome && item.nome.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredItems(tmpFiltered);
    setFilterText(filter);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        setFilteredItems(data);
      }
    };

    return (
      <input
        placeholder="Cerca per nome"
        className="border border-solid rounded-lg border-gray pl-4"
        type="text"
        value={filterText}
        onChange={(e) => {
          goFilter(e.target.value);
        }}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Id ricetta",
      selector: (row) => row.id_ricetta,
      sortable: true,
    },
    {
      name: "Nome",
      selector: (row) => row.nome,
      sortable: true,
    },
    {
      name: "Alimento",
      selector: (row) => row.nome_alimento,
      sortable: true,
    },
    {
      name: "Gestisci",
      selector: (row) => {
        return (
          <div className="flex justify-evenly">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={tooltipDeleteRecipe}
            >
              <Button
                className="mx-2"
                onClick={() => {
                  setSelectedRicetta(row.id_ricetta);
                  setMessageDelete("")
                  handleShow();
                }}
              >
                <FaTrash />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={tooltipReadRecipe}
            >
              <Button
                className="mx-2"
                onClick={() => navigate("/recipes/read/" + row.id_ricetta)}
              >
                <FaEye />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={tooltipUpdateRecipe}
            >
              <Button
                className="mx-2"
                onClick={() => navigate("/recipes/update/" + row.id_ricetta)}
              >
                <FaPen />
              </Button>
            </OverlayTrigger>
          </div>
        );
      },
      sortable: true,
    },
  ];

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "Tutte",
    rowsPerPageText: "Righe per pagina",
    rangeSeparatorText: "di",
  };

  const customStyles = {
    subHeader: {
      style: {
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      },
    },
    pagination: {
      style: {
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      },
    },
    rows: {
      cursor: "pointer",
    },
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attezione!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sei sicuro di voler eliminare la ricetta con id {selectedRicetta} ?
          <p className="text-orange-900	">{messageDelete}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
          <Button variant="danger" onClick={removeRicetta}>
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="w-full h-full justify-center items-center container mx-auto align-middle">
        <DataTable
          customStyles={customStyles}
          columns={columns}
          data={filteredItems}
          pagination={true}
          paginationComponentOptions={paginationComponentOptions}
          striped
          paginationPerPage={8}
          defaultSortFieldId={1}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          pointerOnHover={true}
        />
      </div>
    </div>
  );
};

export default RecipesTable;
