import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { set } from "../features/counter/counterSlice";
import logo from "../logolungo.png";

import { isMobile } from "react-device-detect";
import loginImage from "../dtedoardo.jpg";
import { login } from "../utils";
const Login = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();



  useEffect(() => {
    console.log("ok1")
    const script = document.createElement('script');

    script.src = "./myscript.js";
    script.async = false;
    script.type = "text/jsx";


    document.body.appendChild(script);
    console.log("ok2")

    return () => {
      console.log("ok3")
      document.body.removeChild(script);
    }


  }, []);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const logIn = async () => {
    const { err, data } = await login(email, password);
    if (err) {
      alert(err);
    }
    ;
    console.log(data);
    if (data.error === "3") {
      alert("Password errata");
      return;
    }
    let user = {
      idUtente: data.idUtente.toString(),
      email: data.email || "",
      tipoUtente: data.tipoUtente || "",
      nome: data.nome || "",
      cognome: data.cognome || "",
      data_nascita: data.data_nascita || "",
      cf: data.cf || "",
      indirizzo: data.indirizzo || "",
      loc: data.loc || "",
      cap: data.cap || "",
      prov: data.prov || "",
      nazione: data.nazione || "",
      luogoNazione: data.luogoNazione || "",
      telFisso: data.telFisso || "",
      telCellulare: data.telCellulare || "",
    };
    dispatch(set(user));
    navigate("/homepage");
  };

  if (isMobile) {
    return (
      <div className="w-full h-full justify-center items-center container mx-auto align-middle">
        <div className="flex col-span-1  flex-col justify-center items-center container mx-auto align-middle">
          <img
            src={logo}
            className="flex w-80 bg-white rounded"
            alt="Logo"
          ></img>
          <p className="flex text-xl text-center text-black w-5/6">
            Per visualizzare questa pagina è necessario utilizzare un computer.
            L'applicazione per smartphone è attualmente in lavorazione.
          </p>
        </div>
        {/*  <div className='w-5/6 h-1/3 grid grid-cols-2 justify-center items-center container mx-auto align-middle'>
        
          <div className='pr-10 flex col-span-1  flex-col justify-center items-center container mx-auto align-middle'>
          <a href="https://play.google.com/store/apps/details?id=it.firenzewebdivision.ersaf_project">
              <img alt="Google Play Store" src={playStore} className="flex w-full border-solid border-gray-300 border-2 rounded-lg my-0 " />

            </a>

          </div>
          <div className='pl-10 flex col-span-1  flex-col justify-center items-center container mx-auto align-middle'>
            <a href="itms-apps://itunes.com/apps/ersafProject">
              <img alt="Apple Store" src={appStore} className=" flex w-full border-solid border-gray-300 border-2 rounded-lg my-0 " />

            </a>
          </div>
        </div> */}
      </div>
    );
  } else {
    return (
      <div className="w-full h-full grid grid-cols-2  ">
        <div className="flex col-span-1  flex-col justify-center items-center container mx-auto align-middle">
          <img
            src={logo}
            className="flex w-80 bg-white rounded mb-10"
            alt="Logo"
          ></img>
          <p className="flex text-center text-gray-400">
            Inserisci il tuo indirizzo email e la password per accedere alla
            piattaforma
          </p>

          <input
            type="email"
            className="flex rounded-tl-xl rounded-tr-xl mt-8 border-t border-l border-r border-b border-[#D0FCAB] outline-[#D0FCAB] focus:outline p-2 w-4/6 h-20 mb-2"
            placeholder="Indirizzo email"
            value={email}
            onChange={onChangeEmail}
          />
          <input
            type="password"
            className="outline-[#D0FCAB] focus:outline mt-0 rounded-bl-xl rounded-br-xl border-[#D0FCAB] flex border  p-2 w-4/6 h-20"
            placeholder="Password"
            value={password}
            onChange={onChangePassword}
          />
          {/*<div className="w-4/6 flex pb-5 items-end justify-end content-end">
            <a href='#' className='mt-8 flex items-end justify-end text-gray-600 underline text-sm'>Hai dimenticato la password?</a>
          </div>
    */}
          <input
            type="button"
            className="mt-8 h-14 w-1/3 mb-20 hover:text-[#912F5C] text-white text-center  content-center bg-[#9DCA6C] rounded-2xl pl hover:bg-[#F2DCB8] hover:cursor-pointer"
            value="Accedi alla piattaforma"
            onClick={() => logIn()}
          />
        </div>
        <div className="flex col-span-1  flex-col justify-center items-center container overflow-hidden align-middle">
          <img
            src={loginImage}
            className="flex object-fill bg-white rounded "
            alt="LoginImage"
          ></img>
        </div>
        <div className="flex flex-col m-5 justify-center align-center">
          <div className="flex justify-evenly	">
            {" "}
            <a
              href="https://www.iubenda.com/privacy-policy/45803042/cookie-policy"
              className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
              title="Cookie Policy "
            >
              Cookie Policy
            </a>
            <a
              href="https://www.iubenda.com/privacy-policy/45803042"
              className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
              title="Privacy Policy "
            >
              Privacy Policy
            </a>
            <a
              href="https://www.iubenda.com/termini-e-condizioni/45803042"
              className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
              title="Termini e Condizioni "
            >
              Termini e Condizioni
            </a>
          </div>
          <p className="justify-self-center mt-5 mx-5">
            © Copyright 2023. All Rights Reserved. Firenze Web Division S.r.l.
          </p>
        </div>
        
      </div>
    );
  }
};

export default Login;
