import { useNavigate } from "react-router-dom";

import { Loading } from "./loading";

import UserToolTip from "./UserToolTip";
const axios = require("axios").default;

const login = async (email, password) => {
  if (!email || !password) return alert("Missing email or password");
  const req = axios.create({
    baseURL:
      "https://appedoardobanchinutrizionista.it"  +
      process.env.REACT_APP_BASE_URL /* "http://178.237.3.115/api" */,
  });
  req.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.code == 401) {
        logout();
      }
      return Promise.reject(error);
    }
  );
  let { err, data } = await req.post("/login", { email, password });
  if (err) {
    return { err: err, data: null };
  }
  if (data.error === "3") {
    alert("Password errata");
    return;
  }
  localStorage.setItem("accessToken", data.accessToken);
  localStorage.setItem("idUtente", data.idUtente);
  localStorage.setItem("nome", data.nome);
  localStorage.setItem("cognome", data.cognome);
  
  return { err: null, data: data };
};

const logout = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("idUtente");
  localStorage.removeItem("nome");
  localStorage.removeItem("cognome");
};

const useRequest = () => {
  const navigate = useNavigate();
  const request = axios.create({
    baseURL:
       "https://appedoardobanchinutrizionista.it" +
      process.env.REACT_APP_BASE_URL
      
      /* "http://178.237.3.115/api" */ ,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
  request.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response.status == 500) {
        return Promise.reject(error);
      }
      if (error.response.data !== undefined && error.response.status == 401) {
        logout();
        navigate("/login");
        return;
      }
      return Promise.reject(error);
    }
  );
  return request;
};

const checkIsNumber = (e) => {
  if (
    !(
      e.key == "0" ||
      e.key == "1" ||
      e.key == "2" ||
      e.key == "3" ||
      e.key == "4" ||
      e.key == "5" ||
      e.key == "6" ||
      e.key == "7" ||
      e.key == "8" ||
      e.key == "9" ||
      e.key == "," ||
      e.key == "Backspace" ||
      e.key == "ArrowRight" ||
      e.key == "ArrowLeft"
    )
  ) {
    e.preventDefault();
  }
};

export { login, logout, useRequest, Loading, UserToolTip,checkIsNumber };
