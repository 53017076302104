import React, { useEffect, useState } from "react";


const parseJwt = (token) => {

  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const PrivacyPage = () => {
  const [htmlContent, setHtmlContent] = useState(null);
  let token = localStorage.getItem("accessToken");
  const decodedJwt = parseJwt(token);
  console.log("decoded jwt");
  console.log(decodedJwt);


  useEffect(() => {

    // Function to add the script
    const addIubendaScript = () => {
      const script = document.createElement('script');
      script.src = "https://cdn.iubenda.com/iubenda.js";
      script.async = true;
      document.body.appendChild(script);
    };

    // Load the Iubenda script on mount
    addIubendaScript();

    // Optional: Cleanup the script when the component unmounts
    return () => {
      const scripts = document.getElementsByTagName('script');
      for (let i = scripts.length - 1; i >= 0; i--) {
        if (scripts[i].src === "https://cdn.iubenda.com/iubenda.js") {
          scripts[i].parentNode.removeChild(scripts[i]);
        }
      }
    };
  }, []);

  return (
    <div className="flex rounded-tr-2xl rounded-br-2xl flex-col w-full h-full bg-secondary bg-opacity-60 p-2">
      
      <div id="tabs" className="w-full ">
        {/* The anchor tag provided by Iubenda */}
        {/*  <a href="https://www.iubenda.com/termini-e-condizioni/45803042" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Termini e Condizioni">Termini e Condizioni</a> */}
        <a href="https://www.iubenda.com/privacy-policy/45803042" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Privacy Policy">Privacy Policy</a>
      </div>
    </div>
  );
};

export default PrivacyPage;
