import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import { FaInfo } from "react-icons/fa";
import { checkIsNumber } from "../utils";

export function Alimento(props) {
  var alimento = props.alimento;
  const listAlternative = props.alternative;
  const alternativeUM = props.alternativeUM;
  const alternativeFreq = [
    "-",
    "A frequenza libera",
    "Una volta alla settimana",
    "Due volte alla settimana",
  ];
  const [isCheck, setIsCheck] = useState(alimento.selezionato);
  const [open, setOpen] = useState(false);
  const [dosaggio1, setDosaggio1] = useState(alimento.dosaggio1 || null);
  const [dosaggioDesc, setDosaggioDesc] = useState(
    alimento.dosaggioDesc || null
  );
  const [id_unitaMisura1, setUnitaMisura1] = useState(
    alimento.unitaMisura1 != null && alimento.unitaMisura1 != "-"
      ? props.alternativeUM.find((um) => um.nome == alimento.unitaMisura1)
          .id_unitaMisura
      : null
  );
  const [nomeUnitaMisura1, setNomeUnitaMisura1] = useState(
    alimento.unitaMisura1 || "-"
  );

  const [change, setChange] = useState(false);
  const [filteredItems, setFilteredItems] = useState(props.alternative || []);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    alimento.dosaggio1 = dosaggio1;
    alimento.id_unitaMisura1 = id_unitaMisura1;
    alimento.unitaMisura1 = nomeUnitaMisura1;

    alimento.dosaggioDesc = dosaggioDesc;
    if (alimento.alternative == null) {
      alimento.alternative = [];
    }

    setNomeUnitaMisura1(alimento.unitaMisura1 ?? "-");
  }, []);

  const handleOpen = () => setOpen(!open);
  const handleClose = () => setOpen(false);

  const handleSave = () => {
    alimento.dosaggio1 = dosaggio1;
    alimento.id_unitaMisura1 = id_unitaMisura1;
    var um = alternativeUM.find(
      (unita) => unita.id_unitaMisura == alimento.id_unitaMisura1
    );
    alimento.unitaMisura = nomeUnitaMisura1;
    alimento.dosaggioDesc = dosaggioDesc;
    if (alimento.alternative.length > 0) {
      alimento.alternative.map((alternativa) => {
        if (alternativa.frequenza == "-") alternativa.frequenza = null;
        if (alternativa.id_unitaMisura1 == "-") {
          alternativa.id_unitaMisura1 = null;
        } else {
          var um = alternativeUM.find(
            (unita) => unita.id_unitaMisura == alternativa.id_unitaMisura1
          );
          alternativa.unitaMisura1 = um != null ? um.nome : null;
        }
      });
    }
    setOpen(!open);
    props.saveAlimento(alimento);
  };

  const hanldeSelezionato = () => {
    alimento.selezionato = !isCheck;
    setIsCheck(!isCheck);
    props.hanldeSelezionato(alimento);
  };
  const handleDosaggio1 = (value) => {
    setDosaggio1(value.replace(",", "."));
  };

  const handleDosaggioDesc = (value) => {
    setDosaggioDesc(value);
  };
  const handleUnitaMisura1 = (value) => {
    setNomeUnitaMisura1(value);
    let id = alternativeUM.find((unita) => unita.nome == value).id_unitaMisura;
    setUnitaMisura1(id);
  };

  const handleDosaggio1Alt = (e, alternativa) => {
    var alt = alimento.alternative.find(
      (el) => (el = el.id_alimento == alternativa.id_alimento)
    );

    alt.dosaggio1 = e.target.value.replace(",", ".");
    setChange(!change);
  };

  const handleUnitaMisura1Alt = (e, alternativa) => {
    var alt = alimento.alternative.find(
      (el) => (el = el.id_alimento == alternativa.id_alimento)
    );
    alt.unitaMisura1 = e.target.value;
    var um = alternativeUM.find((unita) => unita.nome == alt.unitaMisura1);
    alt.id_unitaMisura1 = um != null ? um.id_unitaMisura : null;
    setChange(!change);
  };

  const handleFrequenzaAlt = (e, alternativa) => {
    var alt = alimento.alternative.find(
      (el) => (el = el.id_alimento == alternativa.id_alimento)
    );
    alt.frequenza = e.target.value;
    setChange(!change);
  };

  const isCheckAlt = (id_alimento) => {
    if (alimento.alternative != null && alimento.alternative.length > 0) {
      const index = alimento.alternative.findIndex(
        (alt) => alt.id_alimento == id_alimento
      );
      return index != -1;
    }
    return false;
  };
  
  const hanldeSelezionatoAlt = (alternativa) => {
    var newAlternativa = {
      id_alimento: alternativa.id_alimento,
      nome: alternativa.nome,
      id_alimentoMaster: alimento.id_alimento,
      dosaggio1: null,
      frequenza: null,
      dosaggioDesc: null,
      id_unitaMisura1: null,
      unitaMisura1: null,
    };
    if (alimento.alternative != null) {
      var index = alimento.alternative.findIndex(
        (el) => el.id_alimento == newAlternativa.id_alimento
      );
      if (index != -1) {
        alimento.alternative = alimento.alternative.filter(
          (el) => el.id_alimento != newAlternativa.id_alimento
        );
      } else alimento.alternative = [...alimento.alternative, newAlternativa];
    } else {
      alimento.alternative = [];
      alimento.alternative.push(newAlternativa);
    }
    setChange(!change);
  };

  const goFilter = async (filter) => {
    let tmpFiltered = listAlternative.filter(
      (alternativa) =>
        alternativa.nome &&
        alternativa.nome.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredItems(tmpFiltered);
    setFilterText(filter);
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        setFilteredItems(listAlternative);
      }
    };

    return (
      <input
        placeholder="Cerca per nome"
        className="border border-solid rounded-lg border-gray pl-4"
        type="text"
        value={filterText}
        onChange={(e) => {
          goFilter(e.target.value);
        }}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="h-full">
      <Modal
        show={open}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader>{alimento.nome}</ModalHeader>
        <ModalBody>
          <div className="flex flex-col">
            <Form
              id="exampleForm"
              className="flex flex-row m-2 justify-between"
            >
              <Form.Group className="ml-2 flex flex-col ">
                <Form.Label htmlFor="inputDosaggio1">
                  Dosaggio (es. 15 gr)
                </Form.Label>
                <div className="p-1 flex ">
                  <div className="pr-2">
                    <Form.Control
                      defaultValue={dosaggio1}
                      value={dosaggio1}
                     
                      id="inputDosaggio1"
                      onChange={(e) => {handleDosaggio1(e.target.value); }}
                      onKeyDown={(e) => {
                        checkIsNumber(e);
                      }}
                    />
                  </div>
                  <div className="px-2">
                    <Form.Select
                      {...(Alimento.dosaggio1 != null ? null : "disabled")}
                      label="Unita di misura"
                      variant="outlined"
                      defaultValue={nomeUnitaMisura1}
                      onChange={(e) => {
                        handleUnitaMisura1(e.target.value);
                      }}
                    >
                      <option>-</option>
                      {alternativeUM.map((um) => {
                        return <option>{um.nome}</option>;
                      })}
                    </Form.Select>
                  </div>
                </div>
              </Form.Group>
              <div className="flex">
                <Form.Group className="p-2">
                  <Form.Label htmlFor="inputdosaggioDesc">
                    Secondo dosaggio (opzionale)
                  </Form.Label>
                  <Form.Control
                    defaultValue={dosaggioDesc}
                    type="text"
                    id="inputdosaggioDesc"
                    onChange={(e) => {
                      handleDosaggioDesc(e.target.value);
                    }}
                  />
                </Form.Group>
              </div>
            </Form>
            <div className="flex justify-between ">
              <p className="text-xl mx-4">Seleziona le alternative</p>
              <div className="flex justify-end"> {subHeaderComponentMemo}</div>
            </div>
            <div className="mb-4 grid grid-cols-2 max-sm:grid-cols-1 grid-rows-1 gap-1 w-full p-2 mt-3 overflow-y-scroll">
              {filteredItems.map((alternativa) => {
                return (
                  <Card
                    key={alternativa.id_alimento}
                    style={{
                      backgroundColor: isCheckAlt(alternativa.id_alimento)
                        ? "#D4E8A3"
                        : "#ffffff",
                    }}
                  >
                    <Card.Body className="text-center flex flex-col ">
                      <div className="text-center flex ">
                        <div className="block p-2">
                          <div key={`default-checkbox`} className="m-1">
                            <Form.Check
                              type="checkbox"
                              id="default-checkbox"
                              defaultChecked={isCheckAlt(
                                alternativa.id_alimento
                              )}
                              onChange={() => {
                                hanldeSelezionatoAlt(alternativa);
                              }}
                            />
                          </div>
                        </div>
                        <div className="p-2 flex flex-1 grow justify-center items-center ">
                          {alternativa.nome}
                        </div>
                      </div>

                      {isCheckAlt(alternativa.id_alimento) ? (
                        <div className="flex flex-col">
                          <div className=" flex flex-row m-2">
                            <Form id="exampleForm">
                              <Form.Group className="ml-2 flex flex-col ">
                                <Form.Label htmlFor="inputDosaggio1Alt">
                                  Dosaggio
                                </Form.Label>
                                <div className="p-1 flex ">
                                  <div className="pr-2">
                                    <Form.Control
                                      value={
                                        alimento.alternative.find(
                                          (el) =>
                                            (el =
                                              el.id_alimento ==
                                              alternativa.id_alimento)
                                        ).dosaggio1
                                      }
                                      id="inputDosaggio1"
                                      onChange={(e) => {
                                        handleDosaggio1Alt(e, alternativa);
                                      }}
                                      onKeyDown={(e) => {
                                        checkIsNumber(e);
                                      }}
                                    />
                                  </div>
                                  <div className="px-2">
                                    <Form.Select
                                      label="Unita di misura"
                                      variant="outlined"
                                      defaultValue={
                                        alimento.alternative.find((el) => {
                                          return (el =
                                            el.id_alimento ==
                                            alternativa.id_alimento);
                                        }).unitaMisura1
                                      }
                                      onChange={(e) => {
                                        handleUnitaMisura1Alt(e, alternativa);
                                      }}
                                    >
                                      <option>-</option>
                                      {alternativeUM.map((um) => {
                                        return <option>{um.nome}</option>;
                                      })}
                                    </Form.Select>
                                  </div>
                                </div>
                              </Form.Group>
                              <Form.Group className="p-2">
                                <Form.Label htmlFor="inputDosaggio1Alt">
                                  Frequenza
                                </Form.Label>
                                <Form.Select
                                  label="Frequenza"
                                  variant="outlined"
                                  defaultValue={
                                    alimento.alternative.find(
                                      (el) =>
                                        (el =
                                          el.id_alimento ==
                                          alternativa.id_alimento)
                                    ).frequenza == null
                                      ? "-"
                                      : alimento.alternative.find(
                                          (el) =>
                                            (el =
                                              el.id_alimento ==
                                              alternativa.id_alimento)
                                        ).frequenza
                                  }
                                  onChange={(e) => {
                                    handleFrequenzaAlt(e, alternativa);
                                  }}
                                >
                                  {alternativeFreq.map((freq) => {
                                    return <option>{freq}</option>;
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </Form>
                          </div>
                        </div>
                      ) : null}
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="text"
            color="green"
            onClick={handleSave}
            className="mr-1"
          >
            <span>Salva</span>
          </Button>
        </ModalFooter>
      </Modal>
      <Card className=" h-full">
        <Card.Body className="text-center flex flex-row  max-md:flex-col">
          <div className="block p-2">
            <div key={`default-checkbox`} className="m-1">
              <Form.Check
                type="checkbox"
                id="default-checkbox"
                defaultChecked={isCheck}
                onChange={() => {
                  hanldeSelezionato();
                }}
              />
            </div>
          </div>
          <div className="m-1">
            <img
              alt="img-blur-shadow"
              className="w-12 h-12"
              src={"/alimenti/" + alimento.icona + ".png"}
            />
          </div>
          <div className="p-2 flex flex-1 grow justify-center items-center ">
            {alimento.nome}
          </div>
          {isCheck ? (
            <div className="text-center pt-2 px-2">
              <Button
                className="p-2"
                onClick={handleOpen}
                children={<FaInfo></FaInfo>}
              ></Button>
            </div>
          ) : null}
        </Card.Body>
      </Card>
    </div>
  );
}
