import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CloseButton,
  Form,
  Modal,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { FaSadCry } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

import { checkIsNumber, Loading, useRequest } from "../../utils";

const RecipesCU = () => {
  const req = useRequest();
  const { id } = useParams();
  const isUpdate = id != null;
  const [loading, setLoading] = useState(true);

  const [ricetta, setRicetta] = useState({});
  const [nomeRicetta, setNomeRicetta] = useState("");
  const [alimento, setAlimento] = useState("-1");
  const [ingredienti, setIngredienti] = useState([]);
  const [procedimento, setProcedimento] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const [listAlimenti, setListAlimenti] = useState([]);
  const [listUnitaMisura, setListUnitaMisura] = useState([]);
  const [change, setChange] = useState(false);
  const [idIngr, setIdIngr] = useState(10000);
  useEffect(() => {
    const getData = async () => {
      await getAlimenti();
      await getUnitaMisura();
      if (isUpdate) {
        await getRicetta();
      }
      setLoading(false);
    };
    getData();
  }, []);

  const getRicetta = async () => {
    let res = await req.get(`/recipe/${id}`);
    console.log("RESULT GET ricetta:");
    console.log(res);

    delete res.success;
    delete res.error;
    delete res.message;
    setRicetta(res.data);
    setNomeRicetta(res.data.nome);
    setIngredienti(res.data.ingredienti);
    setProcedimento(res.data.descrizione);
    setAlimento(res.data.id_alimento ?? null);
  };

  const getAlimenti = async () => {
    let res = await req.get(`/alimento/all`);
    console.log("RESULT ALIMENTI:");
    console.log(res);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    setListAlimenti(res.data.data);
  };

  const getUnitaMisura = async () => {
    let res = await req.get(`/unitaMisura`);
    console.log("RESULT UNITA DI MISURA:");
    console.log(res);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    setListUnitaMisura(res.data.data);
  };

  const selezionaAlimento = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const onSave = async () => {
    if (checkRicetta()) {
      ricetta.nome = nomeRicetta;
      ricetta.descrizione = procedimento;
      ricetta.ingredienti = ingredienti;
      ricetta.id_alimento = alimento;
      var idRicetta = "-1";
      try {
        if (isUpdate) {
          let { data: res } = await req.put(`/recipe`, {
            id_utenteApp: localStorage.getItem("idUtente"),
            recipe: ricetta,
          });

          idRicetta = res.id_ricetta;
          console.log(res);
        } else {
          let { data: res } = await req.post(`/recipe`, {
            id_utenteApp: localStorage.getItem("idUtente"),
            recipe: ricetta,
          });

          idRicetta = res.id_ricetta;
          console.log(res);
        }
        setLoading(false);
        navigate("/recipes/read/" + idRicetta);
      } catch (e) {
        setLoading(false);
        setShowAlert(true);
        setAlertMessage("Errore nel salvataggio della ricetta");
      }
    } else {
      setLoading(false);
      setShowAlert(true);
      setAlertMessage(
        "La ricetta deve avere associato almeno un ingrediente e l'alimento!"
      );
    }
  };

  const setNomeIngr = (e, index) => {
    var newListIngr = ingredienti;
    newListIngr[index].nome = e.target.value;
    setIngredienti(newListIngr);
  };
  const setUMIngr = (e, index) => {
    var newListIngr = ingredienti;
    newListIngr[index].unitaMisura = e.target.value;
    var um = listUnitaMisura.find((um) => um.nome == e.target.value);
    newListIngr[index].id_unitaMisura = um != null ? um.id_unitaMisura : null;
    setIngredienti(newListIngr);
    setChange(!change);
  };
  const setDosaggioIngr = (e, index) => {
    var newListIngr = ingredienti;
    newListIngr[index].dosaggio = e.target.value;
    setIngredienti(newListIngr);
  };
  const removeIngredient = (id) => {
    var newListIngr = ingredienti.filter((ingr) => ingr.id_ingrediente != id);
    setIngredienti(newListIngr);
  };
  const checkRicetta = () => {
    if (alimento != null && ingredienti.length > 0) {
      return true;
    }
    return false;
  };
  return (
    <div className="w-full h-full">
      <ToastContainer className="p-3" position="top-end">
        <Toast
          bg="danger"
          delay={3000}
          autohide
          onClose={() => setShowAlert(false)}
          show={showAlert}
        >
          <Toast.Header>Attenzione!</Toast.Header>
          <p className="m-2">{alertMessage}</p>
          <hr />
        </Toast>
      </ToastContainer>
      <Modal show={showModal} size="lg">
        <Modal.Header>Seleziona alimento</Modal.Header>
        <Modal.Body>
          <div className="mb-4 grid grid-cols-3 max-sm:grid-cols-1 grid-rows-1 gap-1 w-full p-2 mt-3 overflow-y-scroll">
            {listAlimenti.map((alternativa) => {
              return (
                <Card key={alternativa.id_alimento}>
                  <Card.Body className="text-center flex flex-col ">
                    <div className="text-center flex ">
                      <div className="block p-2">
                        <div key={`default-checkbox`} className="m-1">
                          <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            checked={
                              alimento != "-1"
                                ? alimento == alternativa.id_alimento
                                : false
                            }
                            onChange={() => {
                              setAlimento(alternativa.id_alimento);
                            }}
                          />
                        </div>
                      </div>
                      <div className="p-2 flex flex-1 grow justify-center items-center ">
                        {alternativa.nome}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Chiudi</Button>
          <Button onClick={closeModal}>Salva</Button>
        </Modal.Footer>
      </Modal>
      <div className="flex flex-col items-center h-full m-2">
        <Loading className="my-auto" loading={loading}>
          <div id="header" className="flex w-full justify-between">
            <h1 className="text-[#802727] text-4xl">
              {isUpdate ? "Modifica ricetta" : "Crea una nuova ricetta"}
            </h1>
            <div className="flex justify-center  mx-2">
              <Button className="mt-2 mb-2" onClick={onSave}>
                {isUpdate ? "Salva modifiche" : "Crea ricetta"}
              </Button>
            </div>
          </div>
          <div className="flex flex-col w-full ">
            <div className="flex flex-col justify-start">
              <div className="p-2">
                <Form.Label htmlFor="inputNome">Nome:</Form.Label>
                <Form.Control
                  defaultValue={nomeRicetta}
                  as="input"
                  id="inputNome"
                  onChange={(e) => setNomeRicetta(e.target.value)}
                />
              </div>
              <div className="p-2">
                <div className="flex justify-between ">
                  <div className="p-1 flex">
                    <div className="pe-1"> Alimento:</div>
                    {alimento != "-1" && listAlimenti.length > 0 && (
                      <div>
                        {
                          listAlimenti.find((al) => al.id_alimento == alimento)
                            .nome
                        }
                      </div>
                    )}
                  </div>
                  <div className="p-1">
                    <Button onClick={selezionaAlimento}>
                      {alimento == null
                        ? "Seleziona alimento"
                        : "Cambia alimento "}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col p-2">
                <Form.Label htmlFor="inputProcedimento">
                  Procedimento:
                </Form.Label>
                <Form.Control
                  defaultValue={procedimento}
                  as="textarea"
                  rows={3}
                  id="inputPorcedimento"
                  onChange={(e) => setProcedimento(e.target.value)}
                />
              </div>
            </div>
            <div className="my-4 flex flex-col justify-start">
              <div className="flex  justify-between">
                <p>Ingredienti:</p>
                <Button
                  className="mt-2 mb-2"
                  onClick={() => {
                    var newIngrediente = {
                      nome: "",
                      dosaggio: "",
                      unitaMisura: "",
                      id_ingrediente: idIngr,
                    };
                    setIdIngr(idIngr + 1);
                    setIngredienti([...ingredienti, newIngrediente]);
                  }}
                >
                  Aggiungi ingredienti
                </Button>
              </div>
              <div className="mb-4 grid grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 grid-rows-1 gap-1 w-full px-2 ">
                {ingredienti.map((ingr, index) => {
                  return (
                    <div className="mx-6 my-2" key={ingr.id_ingrediente}>
                      <Card>
                        <Card.Header className="flex justify-end align-center">
                          <CloseButton
                            onClick={() =>
                              removeIngredient(ingr.id_ingrediente)
                            }
                          >
                            X
                          </CloseButton>
                        </Card.Header>
                        <Card.Body>
                          <div className="flex flex-col items-center ">
                            <Form.Control
                              defaultValue={ingr.nome}
                              as="input"
                              id="inputNome"
                              onChange={(e) => setNomeIngr(e, index)}
                            />
                            <div className="flex m-2 justify-evenly">
                              <Form.Label
                                className="px-1"
                                htmlFor="inputDosaggio"
                              >
                                Dosaggio:
                              </Form.Label>
                              <Form.Control
                                className="flex basis-1/4 px-1"
                                size="sm"
                                defaultValue={ingr.dosaggio}
                                onChange={(e) => {
                                  setDosaggioIngr(e, index);
                                }}
                                as="input"
                                id="inputDosaggio"
                                onKeyDown={(e) => {
                                  checkIsNumber(e);
                                }}
                              />
                              <div className="px-2">
                                <Form.Select
                                  size="sm"
                                  label="Unita di misura"
                                  variant="outlined"
                                  value={ingr.unitaMisura}
                                  onChange={(e) => {
                                    setUMIngr(e, index);
                                  }}
                                >
                                  <option key="-1">-</option>
                                  {listUnitaMisura.map((um) => {
                                    return (
                                      <option
                                        key={um.id_unitaMisura}
                                        value={um.nome}
                                      >
                                        {um.nome}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="w-full"></div>
        </Loading>
      </div>
    </div>
  );
};

export default RecipesCU;
