import { useEffect, useState } from "react";

export function Visita(props) {
  var visita = props.visita;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <div>
      <div className="flex">
        <div className="p-1 my-2 mr-5 ml-2 ">
          <p className="">
            <b> Data: </b>
            {visita.dataVisita.getDate() +
              "/" +
              (visita.dataVisita.getMonth() + 1) +
              "/" +
              visita.dataVisita.getFullYear()}
          </p>

          <p>
            <b>Orario:</b> {visita.orarioInizio + " - " + visita.orarioFine}
          </p>
          <p>
            <b>Tipo:</b> {visita.tipo}
          </p>
          <p>
            <b>Studio:</b> {visita.studio}
          </p>
          {visita.numeroFattura && (
            <p>
              <b>Numero fattura:</b> {visita.numeroFattura}
            </p>
          )}
          <p>
            <b>Tariffa:</b> {visita.tariffa} €
          </p>
          <p>
            <b>Pagato:</b> {visita.pagato == "1" ? "Si" : "No"}
          </p>
        </div>
        <div className=" p-1 my-2	ml-5">
          {visita.peso && (
            <p>
              <b>Peso:</b> {visita.peso} kg
            </p>
          )}
          {visita.nota && (
            <p>
              <b>Note:</b> {visita.nota}
            </p>
          )}
        </div>
      </div>
      {open && (
        <div>
          <div className="p-1 my-2 mr-5 ml-2 grid grid-cols-4">
            {visita.plicaTricipitale && (
              <p className="my-2">
                <b>Plica Tricipitale:</b> {visita.plicaTricipitale}
              </p>
            )}
            {visita.plicaSottoscapolare && (
              <p className="my-2">
                <b> Plica Sottoscapolare:</b> {visita.plicaSottoscapolare}
              </p>
            )}
            {visita.plicaAddominale && (
              <p className="my-2">
                <b>Plica Addominale:</b> {visita.plicaAddominale}
              </p>
            )}
            {visita.plicaSoprailiaca && (
              <p className="my-2">
                <b>Plica Soprailiaca:</b> {visita.plicaSoprailiaca}
              </p>
            )}
            {visita.plicaCoscia && (
              <p className="my-2">
                <b>Plica Coscia:</b> {visita.plicaCoscia}
              </p>
            )}
            {visita.circonferenzaPolso && (
              <p className="my-2">
                <b>Circonferenza Polso:</b> {visita.circonferenzaPolso}
              </p>
            )}
            {visita.circonferenzaBraccio && (
              <p className="my-2">
                <b> Circonferenza Braccio:</b> {visita.circonferenzaBraccio}
              </p>
            )}
            {visita.circonferenzaTorace && (
              <p className="my-2">
                <b>Circonferenza Torace:</b> {visita.circonferenzaTorace}
              </p>
            )}
            {visita.circonferenzaVita && (
              <p className="my-2">
                <b>Circonferenza Vita:</b> {visita.circonferenzaVita}
              </p>
            )}
            {visita.circonferenzaAddome && (
              <p className="my-2">
                <b>Circonferenza Addome:</b> {visita.circonferenzaAddome}
              </p>
            )}
            {visita.circonferenzaFianchi && (
              <p className="my-2">
                <b>Circonferenza Fianchi:</b> {visita.circonferenzaFianchi}
              </p>
            )}
            {visita.circonferenzaCosciaProssimale && (
              <p className="my-2">
                <b>Circonferenza Coscia Prossimale:</b>{" "}
                {visita.circonferenzaCosciaProssimale}
              </p>
            )}
            {visita.circonferenzaPolpaccio && (
              <p className="my-2">
                <b>Circonferenza Polpaccio:</b> {visita.circonferenzaPolpaccio}
              </p>
            )}
            {visita.percentualeMassaMagra && (
              <p className="my-2">
                <b>Percentuale Massa Magra:</b> {visita.percentualeMassaMagra}
              </p>
            )}
            {visita.percentualeMassaGrassa && (
              <p className="my-2">
                <b>Percentuale Massa Grassa:</b> {visita.percentualeMassaGrassa}
              </p>
            )}
          </div>
        </div>
      )}
      <div className="flex justify-end px-5 pb-2">
        {(visita.plicaTricipitale ||
          visita.plicaSottoscapolare ||
          visita.plicaAddominale ||
          visita.plicaSoprailiaca ||
          visita.plicaCoscia ||
          visita.circonferenzaPolso ||
          visita.circonferenzaBraccio ||
          visita.circonferenzaTorace ||
          visita.circonferenzaVita ||
          visita.circonferenzaAddome ||
          visita.circonferenzaFianchi ||
          visita.circonferenzaCosciaProssimale ||
          visita.circonferenzaPolpaccio ||
          visita.percentualeMassaMagra ||
          visita.percentualeMassaGrassa) && (
          <button
            className="border border-2 border-primary	rounded-md	 py-1 px-3"
            onClick={handleOpen}
          >
            {open ? "Nasocndi informaizoni" : "Mostra informazioni"}
          </button>
        )}
      </div>
    </div>
  );
}
