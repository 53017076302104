import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Form,
  Nav,
  Toast,
  ToastContainer,
  Overlay
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Giorno from "../../components/Giorno";

import { Loading, useRequest } from "../../utils";

const DietsRC = () => {
  const req = useRequest();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [rapportoComponenti, setRapportoComponenti] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {

    fetchData();
  }, []);


  const fetchData = async () => {
    let res = await req.get(`rapportoComponenti/bydiet/${id}`);
    delete res.success;
    delete res.error;
    delete res.message;
    setRapportoComponenti(res.data.data);
    setLoading(false);

  };

  const saveDieta = async (dieta) => {
    setShowAlert(false);
    try {
      let { data: res } = await req.post(`/rapportoComponenti/save`, {
        rapportoComponenti: rapportoComponenti,
        id: id
      });

      if (res.success != "1") {
        setAlertMessage("Si è verificato un errore");
        setShowAlert(true);
        return
      }


      setLoading(false);
      navigate("/homedietslist");
    } catch (e) {
      setAlertMessage("Si è verificato un errore");
      setShowAlert(true);
      return
    }


  };

  return (
    <div
      className="flex flex-col items-center w-full p-1 "
      style={{ height: "95%" }}
    >
      <ToastContainer className="p-3" position="top-end">
        <Toast
          bg="danger"
          delay={3000}
          autohide
          onClose={() => setShowAlert(false)}
          show={showAlert}
        >
          <Toast.Header>Attenzione!</Toast.Header>
          <p className="m-2">"{alertMessage}</p>
          <hr />
        </Toast>
      </ToastContainer>
      <Loading className="  my-auto" loading={loading}>
        <div id="header" className="flex w-full justify-between">
          <h1 className="text-[#802727] text-4xl">
            Rapporto componenti
          </h1>
          <div className="flex justify-center   mr-60">
            <Button className="mt-2 mb-2 bg-[#99e600]" onClick={saveDieta}>
              {"Salva modifiche"}
            </Button>
          </div>
        </div>
        <div className="w-full">
          <div className="flex max-md:flex-col mx-1">
            <div className="flex flex-col basis-1/5 p-2 ml-20 font-bold">
              Componente
            </div>
            <div className="flex flex-col basis-1/4 p-2 font-bold">
              <div className="pb-2">
                Prime scelte
              </div>
            </div>
            <div className="flex flex-col basis-1/4 p-2 font-bold">
              <div className="pb-2">
                Media portate
              </div>
            </div>
          </div>
          {rapportoComponenti.map((componente) => {
            return (<div className="flex max-md:flex-col mx-1">
              <div className="flex flex-col basis-1/5 p-2 ml-20">
                <Form.Label htmlFor="inputnome"> {componente.nome} </Form.Label>
              </div>
              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <Form.Control
                    defaultValue={componente.primeScelte}
                    type="text"
                    id="inputnome"
                    onChange={(e) => {
                      setRapportoComponenti((oldList) => {
                        return oldList.map((item) => {
                          if (item.nome == componente.nome) {
                            return {
                              ...item,
                              primeScelte: e.target.value,
                            };
                          } else {
                            return item;
                          }
                        });
                      })
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col basis-1/4 p-2">
                <div className="pb-2">
                  <Form.Control
                    defaultValue={componente.mediaPortate}
                    type="text"
                    id="inputnome"
                    onChange={(e) => {

                      setRapportoComponenti((oldList) => {
                        return oldList.map((item) => {
                          if (item.nome == componente.nome) {
                            return {
                              ...item,
                              mediaPortate: e.target.value,
                            };
                          } else {
                            return item;
                          }
                        });
                      })
                    }}
                  />
                </div>
              </div>
            </div>)
          })
          }




          <Alert
            variant="danger"
            dismissible
            onClose={() => setShowAlert(false)}
            show={showAlert}
          >
            <Alert.Heading>Attenzione!</Alert.Heading>
            <p>{alertMessage}</p>
          </Alert>
        </div>

      </Loading >
    </div >
  );
};

export default DietsRC;
