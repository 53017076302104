import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Pasto from "./Pasto";

const Giorno = (props) => {
  const isUpdate = props.isUpdate;
  const giorno = props.giorno;
  const pasti = props.pasti;
  const alimenti = props.alimenti;
  const [alimentiPerPasti, setAlimentiPerPasti] = useState(
    props.alimentiPerPasti
  );
  const [pastoSelezionato, setPastoSelezionato] = useState(
    pasti.length > 0 ? pasti[0].nome : ""
  );
  const [idCategoriaSelezionata, setIdCategoriaSelezionata] = useState(
    pasti.length > 0 ? pasti[0].id_categoria : "-1"
  );

  const unitaMisura = props.unitaMisura;

  const handleSelezionati = (pasto, selezionati, idCategoria) => {
    var index = alimentiPerPasti.findIndex((el) => {
      return el.cat == pasto;
    });

    if (index == -1) {
      var newAlimentiPerPasti = alimentiPerPasti;
      newAlimentiPerPasti.push({
        cat: pasto,
        alimenti: selezionati,
        id_categoria: idCategoria,
      });
      setAlimentiPerPasti(newAlimentiPerPasti);
    } else {
      var newAlimentiPerPasti = alimentiPerPasti;
      newAlimentiPerPasti[index].alimenti = selezionati;
      setAlimentiPerPasti(newAlimentiPerPasti);
    }
    props.handleGiorni(giorno, alimentiPerPasti);
  };

  return (
    <div className="flex flex-col items-center w-full h-full px-2" key={giorno}>
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-evenly mx-1 mb-2">
          <Form.Label className="flex basis-1/4 ">
            Pasto selezionato:
          </Form.Label>
          <Form.Select
            placeholder="Seleziona un pasto"
            name="id_categoria"
            id="id_categoria"
            className="flex "
            defaultValue={1}
            onChange={(e) => {
              var pastoSel = pasti.find((pasto) => {
                return pasto.id_categoria == e.target.value;
              });
              setPastoSelezionato(pastoSel.nome);
              setIdCategoriaSelezionata(pastoSel.id_categoria);
            }}
          >
            {pasti.map((pasto) => {
              return (
                <option key={pasto.id_categoria} value={pasto.id_categoria}>
                  {pasto.nome}
                </option>
              );
            })}
          </Form.Select>
        </div>
      </div>
      <div key={pastoSelezionato + giorno}>
        <Pasto
          alimenti={alimenti}
          isUpdate={isUpdate}
          pasto={pastoSelezionato}
          idCategoria={idCategoriaSelezionata}
          unitaMisura={unitaMisura}
          selezionati={
            alimentiPerPasti.findIndex((el) => {
              return el.cat == pastoSelezionato;
            }) != -1
              ? alimentiPerPasti.find((el) => {
                  return el.cat == pastoSelezionato;
                }).alimenti
              : []
          }
          updateSelezionati={handleSelezionati}
        ></Pasto>
      </div>
    </div>
  );
};

export default Giorno;
