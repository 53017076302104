import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  idUtente: "",
  email: "",
  tipoUtente: "",
  nome: "",
  cognome: "",
  data_nascita: "",
  cf: "",
  indirizzo: "",
  loc: "",
  cap: "",
  prov: "",
  nazione: "",
  luogoNazione: "",
  telFisso: "",
  telCellulare: "",
}
export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    set: (state, action) => {
      // Redux Toolkit allows us to write mutating logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a draft state and produces a brand new
      // immutable state based off those changes
      return {...action.payload}
    },
    unset: (state) => {
      state = initialState;
    },
  },
})

// Action creators are generated for each case reducer function
export const { set, unset } = userSlice.actions

export default userSlice.reducer