import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Route, Routes } from "react-router-dom";
import "./custom.scss";

import { AuthGuard } from "./components/AuthGuard";
import { Home, Login } from "./pages";

import DietRead from "./pages/dietsCRUD/dietsRead";

import DietsCU from "./pages/dietsCRUD/dietsCU";
import DietsLC from "./pages/dietsCRUD/dietsLC";
import DietsRC from "./pages/dietsCRUD/dietsRC";

import UserStats from "./pages/usersData/userStats";

import DietsRecipes from "./pages/dietsCRUD/dietsRecipe";
import HomeAlimentiList from "./pages/homeAlimentiList";
import HomeDietsList from "./pages/homeDietsList";
import HomePage from "./pages/homePage";
import TerminiECondizioni from "./pages/terminiECondizioni";
import HomeRecipesList from "./pages/homeRecipesList";
import HomeUsersList from "./pages/homeUsersList";
import RecipesCU from "./pages/recipesCRUD/recipesCU";
import RecipesRead from "./pages/recipesCRUD/recipesRead";
import UserCU from "./pages/usersCRUD/userCU";
import HomeChatList from "./pages/homeChatList";
import HomeNotification from "./pages/homeNotification";
import NotificationCreate from "./pages/notificationsCRUD/notificationCreate";
import NotificationRead from "./pages/notificationsCRUD/notificationRead";
import NotificationProgrammed from "./pages/notificationsCRUD/programmedNotification";
import PrivacyPage from "./pages/privacy";

function App() {
  return (
    <AuthGuard>
      <DndProvider backend={HTML5Backend}>
        <Routes>
          <Route
            path="/"
            element={
              <AuthGuard>
                <Home />
              </AuthGuard>
            }
          >
            <Route path="homeuserslist" element={<HomeUsersList />} />
            <Route path="homedietslist" element={<HomeDietsList />} />
            <Route path="homerecipesist" element={<HomeRecipesList />} />
            <Route path="homealimentilist" element={<HomeAlimentiList />} />
            <Route path="homenotifiche" element={<HomeNotification />} />
            <Route path="homechatlist" element={<HomeChatList />} />
            <Route path="homepage" element={<HomePage />} />
            <Route path="terminiecondizioni" element={<TerminiECondizioni />} />
            <Route path="privacypage" element={<PrivacyPage />} />

            <Route path="users/update/:id" element={<UserCU />} />
            <Route path="users/create/" element={<UserCU />} />
            <Route
              path="users/userStats/:id/:name/:cognome"
              element={<UserStats />}
            />
            <Route path="diets/create" element={<DietsCU />} />
            <Route path="diets/read/:id" element={<DietRead />} />
            <Route path="diets/update/:id/:name" element={<DietsCU />} />
            <Route path="diets/listacomponenti/:id" element={<DietsLC />} />
            <Route path="diets/recipes/:id" element={<DietsRecipes />} />
            <Route path="diets/rapportocomponenti/:id" element={<DietsRC />} />
            <Route path="recipes/read/:id" element={<RecipesRead />} />
            <Route path="recipes/update/:id" element={<RecipesCU />} />
            <Route path="recipes/create/" element={<RecipesCU />} />
            <Route
              path="notification/create/"
              element={<NotificationCreate />}
            />
            <Route
              path="notification/read/:id"
              element={<NotificationRead />}
            />
            <Route
              path="notification/programmed/"
              element={<NotificationProgrammed />}
            />
          </Route>
          <Route path="login" element={<Login />} />
        </Routes>
      </DndProvider>
    </AuthGuard>
  );
}

export default App;
