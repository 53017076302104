import { useState } from "react";
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import { FaInfo } from "react-icons/fa";

export function AlimentoRO(props) {
  var alimento = props.alimento;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);
  const handleClose = () => setOpen(false);

  return (
    <div className="h-full">
      <Modal show={open} onHide={handleClose} size="lg">
        <ModalHeader>{alimento.nome}</ModalHeader>
        <ModalBody>
          <div className="flex flex-col">
            <div className="mb-4 mt-2">
              <p className="text-2xl font-medium text-center ">Alternative</p>
              <div className="grid grid-cols-2 max-sm:grid-cols-1 grid-rows-1 gap-1 w-fulloverflow-y-scroll ">
                {alimento.alternative.map((alternativa) => {
                  return (
                    <Card
                      key={alternativa.id_alimento + alimento.id_alimento}
                      className="m-2  bg-secondary/50"
                    >
                      <Card.Body className="text-center flex flex-col">
                        <div className="text-center flex-col ">
                          <div className="p-1 flex flex-1 grow justify-center items-center  text-xl">
                            {alternativa.nome}
                          </div>
                          <div className="flex flex-col">
                            {alternativa.dosaggio1 != null ? (
                              <div className="flex">
                                <p className="text-base font-medium">
                                  Dosaggio: {"\r"}
                                </p>
                                <p className="text-base">
                                  {alternativa.dosaggio1} {"\r"}
                                  {alternativa.unitaMisura1}
                                </p>
                              </div>
                            ) : null}
                            {alternativa.frequenza != null ? (
                              <div className="flex ">
                                <p className="text-base font-medium">
                                  Frequenza: {"\r"}
                                </p>
                                <p className="text-base">
                                  {alternativa.frequenza}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  );
                })}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="text"
            color="green"
            onClick={handleClose}
            className="mr-1"
          >
            <span>Chiudi</span>
          </Button>
        </ModalFooter>
      </Modal>
      <Card className=" h-full">
        <Card.Body className="text-center flex flex-col">
          <div className="flex">
            <div className="m-1">
              <img
                alt="img-blur-shadow"
                className="w-12 h-12"
                src={"/alimenti/" + alimento.icona + ".png"}
              />
            </div>
            <div className="p-2 flex flex-1 grow justify-center items-center ">
              {alimento.nome}
            </div>
            <div className="text-center pt-2 px-2">
              {alimento.alternative.length > 0 ? (
                <Button
                  className="p-2"
                  onClick={handleOpen}
                  children={<FaInfo></FaInfo>}
                ></Button>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex">
              {alimento.dosaggio1 != null ? (
                <div className="flex">
                  <p className="text-base font-medium">Dosaggio: {"\r"}</p>
                  <p className="text-base">
                    {alimento.dosaggio1} {alimento.unitaMisura1} {"\r"}
                  </p>
                </div>
              ) : null}
              {alimento.dosaggioDesc != null ? (
                <p className="text-base">| {alimento.dosaggioDesc}</p>
              ) : null}
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
