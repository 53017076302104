import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  OverlayTrigger,
  Table,
  Tooltip,
  Form,
} from "react-bootstrap";
import {
  FaCopy,
  FaEye,
  FaPen,
  FaReceipt,
  FaRegListAlt,
  FaTrash,
} from "react-icons/fa";
import { FiDivide } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useRequest } from "../utils";

const DietsTable = () => {
  const [show, setShow] = useState(false);
  const [selectedDieta, setSelectedDieta] = useState(-1);
  const [messageDelete, setMessageDelete] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const req = useRequest();
  const [data, setData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [newDietsName, setNewDietsName] = useState("");

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const navigate = useNavigate();

  const tooltipListaComponenti = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Lista componenti
    </Tooltip>
  );
  const tooltipRicette = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Ricette
    </Tooltip>
  );

  const tooltipRapportoComponenti = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Rapporto componenti
    </Tooltip>
  );
  const tooltipUpdateDiet = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Modifica dieta
    </Tooltip>
  );
  const tooltipReadDiet = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Visualizza dieta
    </Tooltip>
  );
  const tooltiCopyDiet = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Duplica dieta
    </Tooltip>
  );
  const tooltipDeleteDiet = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Elimina dieta
    </Tooltip>
  );

  useEffect(() => {
    getDiets();
  }, []);

  const getDiets = async () => {
    let { data: res } = await req.get(`/diet/all`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    setData(res.data);
    setFilteredItems(res.data);
  };

  const goFilter = async (filter) => {
    let tmpFiltered = data.filter(
      (item) =>
        item.tipo && item.tipo.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredItems(tmpFiltered);
    setFilterText(filter);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        setFilteredItems(data);
      }
    };

    return (
      <input
        placeholder="Cerca per nome"
        className="border border-solid rounded-lg border-gray pl-4"
        type="text"
        value={filterText}
        onChange={(e) => {
          goFilter(e.target.value);
        }}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "Tutte",
    rowsPerPageText: "Righe per pagina",
    rangeSeparatorText: "di",
  };

  const removeDieta = async () => {
    let res = await req.delete(`/diet/${selectedDieta}`);
    console.log(res);
    if (res.data.success == "0") {
      var text =
        "Non è stato possibile eliminare la dieta perche è associata ad alcuni utenti";
      setMessageDelete(text);
    } else {
      delete res.success;
      delete res.error;
      delete res.message;
      handleClose();
      getDiets();
    }
  };

  const duplicateDiet = async () => {
    let res = await req.post(
      `/diet/duplicate/${selectedDieta}/${newDietsName}`
    );
    setOpen(false);
    getDiets();
    setNewDietsName("");
  };
  return (
    <div>
      <Modal show={open} onHide={() => setOpen(false)} size="lg">
        <ModalHeader>Duplica la dieta con id {selectedDieta}</ModalHeader>
        <ModalBody>
          <div className="flex flex-col">
            <div className="mb-4 mt-2">
              <p className="text-2xl font-medium text-center ">
                Inserisci un nome per la nuova dieta
              </p>

              <Form>
                <div className="p-2  ">
                  <div className="pr-2">
                    <Form.Control
                      value={newDietsName}
                      id="inputDosaggio1"
                      onChange={(e) => {
                        setNewDietsName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="text"
            color="green"
            onClick={duplicateDiet}
            className="mr-1"
          >
            <span>Duplica</span>
          </Button>
          <Button
            variant="text"
            color="green"
            onClick={() => setOpen(false)}
            className="mr-1"
          >
            <span>Chiudi</span>
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attezione!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sei sicuro di voler eliminare la dieta con id {selectedDieta} ?
          <p className="text-orange-900	">{messageDelete}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
          <Button variant="danger" onClick={removeDieta}>
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="flex justify-end m-2"> {subHeaderComponentMemo}</div>
      <div className="w-full h-full justify-center items-center container mx-auto align-middle">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Id dieta</th>
              <th>Nome</th>
              <th>Gestisci</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((dieta) => {
              return (
                <tr key={dieta.id_dieta}>
                  <td>{dieta.id_dieta}</td>
                  <td>{dieta.tipo}</td>
                  <td>
                    <div className="flex justify-evenly">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tooltipDeleteDiet}
                      >
                        <Button
                          onClick={() => {
                            setSelectedDieta(dieta.id_dieta);
                            handleShow();
                          }}
                        >
                          <FaTrash></FaTrash>
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tooltipReadDiet}
                      >
                        <Button
                          onClick={() =>
                            navigate("/diets/read/" + dieta.id_dieta)
                          }
                        >
                          <FaEye></FaEye>
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tooltipUpdateDiet}
                      >
                        <Button
                          onClick={() =>
                            navigate("/diets/update/" + dieta.id_dieta + "/" + dieta.tipo)
                          }
                        >
                          <FaPen></FaPen>
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tooltiCopyDiet}
                      >
                        <Button
                          onClick={() => {
                            setSelectedDieta(dieta.id_dieta);
                            setOpen(true);
                          }}
                        >
                          <FaCopy></FaCopy>
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tooltipRicette}
                      >
                        <Button
                          onClick={() =>
                            navigate("/diets/recipes/" + dieta.id_dieta)
                          }
                        >
                          <FaReceipt />
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tooltipListaComponenti}
                      >
                        <Button
                          onClick={() =>
                            navigate("/diets/listacomponenti/" + dieta.id_dieta)
                          }
                        >
                          <FaRegListAlt></FaRegListAlt>
                        </Button>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tooltipRapportoComponenti}
                      >
                        <Button
                          onClick={() =>
                            navigate(
                              "/diets/rapportocomponenti/" + dieta.id_dieta
                            )
                          }
                        >
                          <FiDivide></FiDivide>
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default DietsTable;
