import { createBrowserHistory } from "history";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import store from './app/store';
import AuthVerify from "./common/AuthVerify";
import './index.css';
import reportWebVitals from './reportWebVitals';

let persistor = persistStore(store);
const history = createBrowserHistory({ window });
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HistoryRouter history={history}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
      <AuthVerify history={history}></AuthVerify>
    </HistoryRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
