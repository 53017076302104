import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';
import { BiErrorAlt } from 'react-icons/bi';
import { BsCheckCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useRequest } from '../utils';
import { Button, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { BsClipboardData } from "react-icons/bs";

const UsersTable = () => {
    const req = useRequest();
    const [data, setData] = useState([]);

    const [filteredItems, setFilteredItems] = useState([]);
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const navigate = useNavigate();

    const tooltipContapassi = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Statistiche
        </Tooltip>
    );


    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = async () => {
        let { data: res } = await req.get(`/user/all`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        });

        res.data.forEach((item) => {
            if(item.email === "teamappdigitfwd@gmail.com"){
                console.log(item)
            }
        
        });


        setData(res.data);
        setFilteredItems(res.data);
    }

    const changeAbbonato = async (idUtente, value) => {

        let { data: res } = await req.post(`/user/changeAbbonato`, {

            idUtente: idUtente,
            abbonato: value

        });

        if (res.success === "1") {
            toast("Abbonato cambiato con successo", {
                duration: 2000,
                icon: <BsCheckCircle color="green" />,
            })
        } else {
            toast("Si è verificato un errore", {
                duration: 4000,
                icon: <BiErrorAlt color="red" />,
            })
        }

    }


    const goFilter = async (filter) => {
        let tmpFiltered = data.filter(
            item => {
                let userdata = ((item.nome ?? "").toLowerCase() + (item.cognome ?? "").toLowerCase() + (item.email ?? "").toLowerCase());
                let splitted = filter.toLowerCase().split(" ");
                let found = true;
                for (let i = 0; i < splitted.length; i++) {
                    if (!userdata.includes(splitted[i])) {
                        found = false;
                    }
                }
                return found;
            }
        );
        setFilteredItems(tmpFiltered);
        setFilterText(filter);
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
                setFilteredItems(data);
            }
        };

        return (


            <input placeholder="Cerca per nome" className="border border-solid rounded-lg border-gray pl-4" type="text" value={filterText} onChange={e => {
                goFilter(e.target.value);
            }} />

        );
    }, [filterText, resetPaginationToggle]);


    const columns = [
        {
            name: 'Id utente',
            selector: row => row.idUtente,
            sortable: true
        },
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true
        },
        {
            name: 'Cognome',
            selector: row => row.cognome,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true
        },
        {
            name: 'Abbonato',
            selector: row => {
                return (<select className="shadow  border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" defaultValue={row.abbonato} onChange={(e) => { changeAbbonato(row.idUtente, e.target.value) }}>
                    {row.abbonato === "S" ? <option selected value="S">Si</option> : <option value="S">Si</option>}
                    {row.abbonato === "N" ? <option selected value="N">No</option> : <option value="N">No</option>}
                   
                </select>)
            },
            sortable: true
        },
        {
            name: 'Registrazione',
            selector: row => {
                if (row.tipoReg === "daApp") {
                    return "Da app";
                } else {
                    return "Da studio";
                }
            },
            sortable: true
        },
        {
            name: '',
            maxWidth: "120px",
            selector: row => {
                return (<div className="flex justify-evenly">


                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tooltipContapassi}
                    >
                        <Button
                            onClick={() =>
                                navigate("/users/userStats/" + row.idUtente + "/" + row.nome + "/" + row.cognome)
                            }
                        >
                            <BsClipboardData></BsClipboardData>
                        </Button>
                    </OverlayTrigger>
                </div>)
            }

        }
    ];


    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "Tutte",
        rowsPerPageText: "Righe per pagina",
        rangeSeparatorText: "di",
    };

    const customStyles = {


        subHeader: {
            style: {
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
            },
        },
        pagination: {
            style: {
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
            },
        },
        rows: {
            cursor: 'pointer',
        },


    };


    return (
        <div className='w-full h-full justify-center items-center container mx-auto align-middle'>

            <DataTable
                customStyles={customStyles}
                columns={columns}
                data={filteredItems}
                pagination={true}
                paginationComponentOptions={paginationComponentOptions}
                striped
                paginationPerPage={7}
                defaultSortFieldId={1}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                onRowClicked={(row, event) => {
                    navigate("/users/update/" + row.idUtente)
                    console.log(row);
                }}
                pointerOnHover={true}
            />
        </div>
    );
};



export default UsersTable;

