import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, Form } from "react-bootstrap";
import { Loading, useRequest } from "../utils";

const ChatList = () => {
  const req = useRequest();
  const [data, setData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [textMessage, setTextMessage] = useState("");
  const myDivRef = useRef(null);

  useEffect(() => {
    getChats();
  }, []);
  useEffect(() => {
    if (selectedChat) {
      // Get the div element
      const myDiv = myDivRef.current;
      // Set the scrollTop property to the maximum value
      myDiv.scrollTop = myDiv.scrollHeight - myDiv.clientHeight;
    }
  }, [selectedChat]);
  useEffect(() => {
    const interval = setInterval(() => {
      getChats();
      if (selectedUser != null) openChat(selectedUser.idUtente);
    }, 60000); // 1 minute = 60000 milliseconds
    return () => clearInterval(interval);
  }, [selectedUser]);

  const getChats = async () => {
    let res = await req.get(
      `/message/listUser/` + localStorage.getItem("idUtente")
    );
    console.log("RESULT GET CHAT ALL:");
    console.log(res);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    setData(res.data.data);
    setFilteredItems(res.data.data);
    setLoading(false);
  };

  const openChat = async (idUtente) => {
    var chat = await getChatWith(idUtente);
    if (chat.length > 0) {
      var lastMessage = chat.slice(-1)[0];
      if (
        lastMessage.letto === 0 &&
        lastMessage.idDestinatario == localStorage.getItem("idUtente")
      ) {
        await setMessageRead(lastMessage.idMittente);
        lastMessage.letto = 1;
        chat[chat.length - 1] = lastMessage;
      }
    }
    setSelectedChat(chat);
  };

  const getChatWith = async (idUtente) => {
    let res = await req.get(`/message/chatWith/` + idUtente);
    console.log("RESULT GET CHAT WITH:");
    console.log(res);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    return res.data.data;
  };

  const sendMessageToUser = async () => {
    if (textMessage.trim().length > 0) {
      var msg = {
        idMittente: localStorage.getItem("idUtente"),
        idDestinatario: selectedUser.idUtente,
        testo: textMessage.trim(),
        dataAgg: Date.now(),
      };
      let res = await req.post(`/message`, { msg });
      console.log("RESULT POST MESSAGE:");
      console.log(res);
      if (res.data.error == "1") {
      } else {
        delete res.data.success;
        delete res.data.error;
        delete res.data.message;
        setTextMessage("");
        msg.idMessaggio = res.data.newId;
        var chat = selectedChat;
        chat.push(msg);
        setSelectedChat(chat);
      }
    }
  };

  const setMessageRead = async (id) => {
    
    let res = await req.put(`/message/read/` + id);
    console.log("RESULT PUT MESSAGE:");
    console.log(res);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
  };

  const goFilter = async (filter) => {
    let tmpFiltered = data.filter(
      (chat) =>
        (chat.nome && chat.nome.toLowerCase().includes(filter.toLowerCase())) ||
        (chat.cognome &&
          chat.cognome.toLowerCase().includes(filter.toLowerCase()))
    );
    setFilteredItems(tmpFiltered);
    setFilterText(filter);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
        setFilteredItems(data);
      }
    };

    return (
      <input
        placeholder="Cerca chat per nome"
        className="border border-solid rounded-lg border-gray pl-4"
        type="text"
        value={filterText}
        onChange={(e) => {
          goFilter(e.target.value);
        }}
      />
    );
  }, [filterText]);

  return (
    <div className="flex flex-col items-center w-full p-2 h-full justify-between">
      <div className="flex justify-start mx-2 w-full justify-end">
        {subHeaderComponentMemo}
      </div>
      <Loading className="my-auto" loading={loading}>
        <div className="flex flex-row w-full divide-x divide-black h-full">
          <div className="flex flex-col w-full basis-1/3 px-2 divide-y divide-gray-400 overflow-y-auto overflow-x-hidden h-96">
            {filteredItems.map((user, index) => {
              return (
                <div
                  key={user.idUtente}
                  className={
                    selectedUser != null &&
                    selectedUser.idUtente == user.idUtente
                      ? "p-1 m-1 w-full bg-primary/25 flex"
                      : "p-1 m-1 w-full flex"
                  }
                  onClick={(e) => {
                    user.unread_message = 0;
                    setSelectedUser(user);
                    openChat(user.idUtente);
                  }}
                >
                  {user.unread_message != null && user.unread_message > 0 && (
                    <Badge bg="danger">{user.unread_message}</Badge>
                  )}
                  <p className="px-2">
                    {user.nome} {user.cognome}
                  </p>
                </div>
              );
            })}
          </div>
          {selectedChat != null ? (
            <div className="flex flex-col m-1 basis-2/3 px-2 justify-between items-start w-full h-full ">
              <div className="text-2xl font-semibold text-orange-800">
                Chat con : {selectedUser.nome} {selectedUser.cognome}
              </div>
              <div
                ref={myDivRef}
                className="flex flex-col w-100 overflow-y-auto h-96  p-4"
              >
                {selectedChat.map((msg) => {
                  if (msg.idMittente == localStorage.getItem("idUtente")) {
                    return (
                      <div className="flex justify-end" key={msg.idMessaggio}>
                        <div className="rounded-md border-2 border-primary/50 px-2 bg-primary/50 text-white">
                          {msg.testo}
                        </div>
                      </div>
                    );
                  } else
                    return (
                      <div className="flex justify-start" key={msg.idMessaggio}>
                        <div className="rounded-md border-2 border-white/70 px-2 bg-white/70 text-black m-1">
                          {msg.testo}
                        </div>
                      </div>
                    );
                })}
              </div>
              <div className="flex felx-col w-full mt-auto">
                <div className="flex flex-col w-full mx-1">
                  <Form.Label>Scrivi il tuo messaggio qui</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={textMessage}
                    onChange={(e) => {
                      var newUser = data.find(
                        (user) => user.idUtente == selectedUser.idUtente
                      );
                      if (newUser.unread_message > 0) {
                        var newData = data.map((user) => {
                          if (user.idUtente == selectedUser.idUtente) {
                            user.unread_message = 0;
                          }
                          return user;
                        });
                        var newFilteredData = filteredItems.map((user) => {
                          if (user.idUtente == selectedUser.idUtente) {
                            user.unread_message = 0;
                          }
                          return user;
                        });
                        setData(newData);
                        setFilteredItems(newFilteredData);
                      }
                      setTextMessage(e.target.value);
                    }}
                  />
                </div>
                <div className=" flex">
                  <Button
                    className="mx-2 self-center"
                    onClick={sendMessageToUser}
                  >
                    Invia
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex m-1 basis-2/3 px-2 flex items-center justify-center h-full ">
              <div className="text-2xl font-semibold text-orange-800">
                Seleziona un cliente nella lista per vedere la chat!
              </div>
            </div>
          )}
        </div>
      </Loading>
    </div>
  );
};
export default ChatList;
