import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { NavBar } from "../components/NavBar";
import { TopBar } from "../components/TopBar";
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let token = localStorage.getItem("accessToken");
    if (token != null) {
      const decodedJwt = parseJwt(token);
      console.log(decodedJwt);
      if (decodedJwt.ruolo === "SUPERADMIN") {
      } else if (decodedJwt.ruolo === "ADMIN") {
        navigate("/homepage");
      } else {
        navigate("/homepage");
      }
    } else {
      navigate("/login");
    }
  }, []);
  return (
    <div className="flex flex-col w-full h-full bg-secondary p-0 overflow-y-scroll" >
      <TopBar />
      <NavBar child={<Outlet />} />   
     
    </div>
  );
};

export default Home;
