import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequest } from '../utils';
export const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const request = useRequest()
  const isLogged = async() => {
    if (localStorage.getItem('accessToken') === null)
      return false
    var resp=await request.get("/verifyToken");
    return true;
  }
  useEffect(() => {
    let splitted = window.location.href.trim().split("/")
    console.log(splitted[splitted.length - 1])
    if( splitted[splitted.length - 1] != "privacy")
    {
      if (!isLogged()) {
        console.log("not logged");
        navigate('/login');
      }
  
    }
  }, [])
  return (
     <>{children}</>
  )
}
