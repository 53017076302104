import React, { useState } from "react";
import { Button, Form, Toast, ToastContainer } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Loading, useRequest } from "../../utils";

const NotificationCreate = () => {
  const req = useRequest();
  const [loading, setLoading] = useState(false);

  const [notifica, setNotifica] = useState({});
  const [testo, setTesto] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const onSave = async () => {
    if (checkNotifica()) {
      notifica.testo = testo;

      try {
        let { data: res } = await req.post(`/notification/sendToAllUser`, {
          id_utenteApp: localStorage.getItem("idUtente"),
          notifica: notifica,
        });

        var idNotifica = res.id_notifica;
        console.log(res);

        setLoading(false);
        navigate("/notification/read/" + idNotifica);
      } catch (e) {
        console.log(e.message);
        setLoading(false);
        setShowAlert(true);
        setAlertMessage("Errore nell'invio della notifica");
      }
    } else {
      setLoading(false);
      setShowAlert(true);
      setAlertMessage("Inserire un testo per la notifica");
    }
  };

  const checkNotifica = () => {
    if (testo.length > 0) {
      return true;
    }
    return false;
  };
  return (
    <div className="w-full h-full">
      <ToastContainer className="p-3" position="top-end">
        <Toast
          bg="danger"
          delay={3000}
          autohide
          onClose={() => setShowAlert(false)}
          show={showAlert}
        >
          <Toast.Header>Attenzione!</Toast.Header>
          <p className="m-2">{alertMessage}</p>
          <hr />
        </Toast>
      </ToastContainer>

      <div className="flex flex-col items-center h-full m-2">
        <Loading className="my-auto" loading={loading}>
          <div id="header" className="flex w-full justify-between">
            <h1 className="text-[#802727] text-4xl">Crea una nuova notifica</h1>
            <div className="flex justify-center  mx-2">
              <Button className="mt-2 mb-2" onClick={onSave}>
                Invia notifica
              </Button>
            </div>
          </div>
          <div className="flex flex-col w-full ">
            <div className="flex flex-col justify-start">
              <div className="p-2">
                <Form.Label htmlFor="inputTesto">Testo:</Form.Label>
                <Form.Control
                  defaultValue={testo}
                  id="inputTesto"
                  as="textarea"
                  rows={5}
                  onChange={(e) => setTesto(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="w-full"></div>
        </Loading>
      </div>
    </div>
  );
};

export default NotificationCreate;
