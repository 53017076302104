import React from "react";
import { Toaster } from "react-hot-toast";
import { useRequest } from "../utils";

import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RecipesTable from "./recipes_table";

const HomeRecipesList = () => {
  const req = useRequest();
  const navigate = useNavigate();

  return (
    <div className="flex rounded-tr-2xl rounded-br-2xl flex-col w-full h-full bg-secondary bg-opacity-60 p-2">
      <div id="header" className="flex flex-col w-full mb-4">
        <Toaster />
        <div className="flex justify-between m-2">
          <h1 className="text-[#802727] text-2xl">Ricette</h1>
          <Button
            className="bg-[#802727] text-white hover:bg-[#802727] text-white"
            onClick={() => navigate("/recipes/create")}
          >
            Crea una nuova ricetta
          </Button>
        </div>
        <RecipesTable />
      </div>
    </div>
  );
};

export default HomeRecipesList;
