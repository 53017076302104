import React, { useEffect, useState } from "react";
import { Button, Form, Toast, ToastContainer } from "react-bootstrap";

import { Loading, useRequest } from "../../utils";

const NotificationProgrammed = () => {
  const req = useRequest();
  const [loading, setLoading] = useState(false);

  const [notifiche, setNotifiche] = useState({});
  const [testoUno, setTestoUno] = useState("");
  const [testoDue, setTestoDue] = useState("");
  const [isActiveUno, setIsActiveUno] = useState(true);
  const [isActiveDue, setIsActiveDue] = useState(true);

  const [alertMessageError, setAlertMessageError] = useState("");
  const [showAlertError, setShowAlertError] = useState(false);

  const [alertMessageSuccess, setAlertMessageSuccess] = useState("");
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  useEffect(() => {
    getProgrammedNotifications();
  }, []);

  const getProgrammedNotifications = async () => {
    let res = await req.get(`/notification/programmed`);
    console.log("RESULT GET notifiche programmate:");
    console.log(res);
    delete res.success;
    delete res.error;
    delete res.message;
    setNotifiche(res.data.data);
    if (res.data.data.length == 2) {
      setTestoUno(res.data.data[0].testoNotifica);
      setIsActiveUno(res.data.data[0].attivoSN == "S");
      setTestoDue(res.data.data[1].testoNotifica);
      setIsActiveDue(res.data.data[1].attivoSN == "S");
    }
    setLoading(false);
  };

  const onSave = async () => {
    if (checkNotifica()) {
      var newNotifiche = notifiche;
      newNotifiche.forEach((notifica) => {
        if (notifica.id_testo == 1) {
          notifica.testoNotifica = testoUno;
          notifica.attivoSN = isActiveUno ? "S" : "N";
        } else {
          notifica.testoNotifica = testoDue;
          notifica.attivoSN = isActiveDue ? "S" : "N";
        }
      });
      try {
        let { data: res } = await req.post(`/notification/programmed`, {
          id_utenteApp: localStorage.getItem("idUtente"),
          notifiche: newNotifiche,
        });
        console.log(res);
        setLoading(false);
        setShowAlertSuccess(true);
        setAlertMessageSuccess(res.message);
      } catch (e) {
        setLoading(false);
        setShowAlertError(true);
        setAlertMessageError("Errore nel salvataggio delle notifiche");
      }
    } else {
      setLoading(false);
      setShowAlertError(true);
      setAlertMessageError("Inserire un testo per tutte le notifiche");
    }
  };

  const sendNotification = async (id) => {
    var notifica = { testo: id == 1 ? testoUno : testoDue };
    try {
      let { data: res } = await req.post(`/notification/sendToAllUser`, {
        id_utenteApp: localStorage.getItem("idUtente"),
        notifica: notifica,
      });
      setShowAlertSuccess(true);
      setAlertMessageSuccess(res.message);
    } catch (e) {
      setLoading(false);
      setShowAlertError(true);
      setAlertMessageError("Errore nell'invio della notifica");
    }
  };

  const checkNotifica = () => {
    if (testoUno.length > 0 && testoDue.length > 0) {
      return true;
    }
    return false;
  };
  return (
    <div className="w-full h-full">
      <ToastContainer className="p-3" position="top-end">
        <Toast
          bg="success"
          delay={3000}
          autohide
          onClose={() => setAlertMessageSuccess(false)}
          show={showAlertSuccess}
        >
          <Toast.Header>Completato!</Toast.Header>
          <p className="m-2">{alertMessageSuccess}</p>
          <hr />
        </Toast>
      </ToastContainer>
      <ToastContainer className="p-3" position="top-end">
        <Toast
          bg="danger"
          delay={3000}
          autohide
          onClose={() => setShowAlertError(false)}
          show={showAlertError}
        >
          <Toast.Header>Attenzione!</Toast.Header>
          <p className="m-2">{alertMessageError}</p>
          <hr />
        </Toast>
      </ToastContainer>
      <div className="flex flex-col items-center h-full m-2">
        <Loading className="my-auto" loading={loading}>
          <div id="header" className="flex w-full justify-between">
            <h1 className="text-[#802727] text-4xl">
              Modifica notifiche programmate
            </h1>
            <div className="flex justify-center  mx-2">
              <Button className="mt-2 mb-2" onClick={onSave}>
                Salva modifiche
              </Button>
            </div>
          </div>
          <div className="flex flex-col w-full ">
            <div className="flex flex-col justify-start">
              <div className="p-2">
                <Form.Label htmlFor="inputTesto  text-[#802727]">
                  Notifica 1:
                </Form.Label>
                <Form.Control
                  defaultValue={testoUno}
                  id="inputTesto"
                  as="textarea"
                  rows={5}
                  onChange={(e) => setTestoUno(e.target.value)}
                />
              </div>
              {/* <Form>
                <Form.Check
                  defaultChecked={isActiveUno}
                  type="switch"
                  id="custom-switch"
                  label="Notifica attiva"
                  onChange={() => {
                    setIsActiveUno();
                  }}
                />
              </Form> */}
              <div className="flex justify-end m-3">
                <Button
                  className="mt-2 mb-2"
                  onClick={(e) => sendNotification(1)}
                >
                  Invia notifica
                </Button>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full mt-5 ">
            <div className="flex flex-col justify-start">
              <div className="p-2">
                <Form.Label htmlFor="inputTesto">Notifica 2:</Form.Label>
                <Form.Control
                  defaultValue={testoDue}
                  id="inputTesto"
                  as="textarea"
                  rows={5}
                  onChange={(e) => setTestoDue(e.target.value)}
                />
              </div>
              <div className="p-2">
                {/* <Form>
                  <Form.Check
                    defaultChecked={isActiveDue}
                    type="switch"
                    id="custom-switch"
                    label="Notifica attiva "
                    onChange={() => {
                      setIsActiveDue();
                    }}
                  />
                </Form>
                */}
                <div className="flex justify-end m-3">
                  <Button
                    className="mt-2 mb-2"
                    onClick={(e) => sendNotification(2)}
                  >
                    Invia notifica
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full"></div>
        </Loading>
      </div>
    </div>
  );
};

export default NotificationProgrammed;
