import React, { useEffect, useState } from "react";
import { Button, Card, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaPen, FaTrash } from "react-icons/fa";
import { useRequest } from "../utils";
import AlimentsCU from "./alimentsCRUD/alimentsCU";

const AlimentiList = () => {
  const req = useRequest();
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => {
    setMessageDelete(null);
    setShowModal(true);
  };

  const [selectedAlimento, setSelectedAlimento] = useState("-1");

  const [data, setData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [messageDelete, setMessageDelete] = useState(null);
  const [show, setShow] = useState(false);
  const handleShow = (isNew) => {
    if (isNew) setSelectedAlimento("-1");
    setShow(true);
  };
  const handleClose = (toUpdate) => {
    setShow(false);
    if (toUpdate) {
      getAlimenti();
    }
    //ELSE MESSAGGIO DI ERRORE
  };

  useEffect(() => {
    getAlimenti();
  }, []);
  const tooltipUpdateAlimento = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Modifica
    </Tooltip>
  );
  const tooltipRemoveAlimento = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Elimina
    </Tooltip>
  );
  const getAlimenti = async () => {
    let res = await req.get(`/alimento/all`);
    console.log("RESULT GET ALIMENTI ALL:");
    console.log(res);

    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    setData(res.data.data);
    setFilteredItems(res.data.data);
  };

  const goFilter = async (filter) => {
    let tmpFiltered = data.filter(
      (alimento) =>
        alimento.nome &&
        alimento.nome.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredItems(tmpFiltered);
    setFilterText(filter);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
        setFilteredItems(data);
      }
    };

    return (
      <input
        placeholder="Cerca per nome"
        className="border border-solid rounded-lg border-gray pl-4"
        type="text"
        value={filterText}
        onChange={(e) => {
          goFilter(e.target.value);
        }}
      />
    );
  }, [filterText]);

  const removeAlimento = async () => {
    let res = await req.delete(`/alimento/${selectedAlimento}`);
    console.log(res);
    if (res.data.success == "0") {
      var text =
        "Non è stato possibile eliminare l'alimento perche è presente in alcune diete";
      setMessageDelete(text);
    } else {
      handleCloseModal();
      getAlimenti();
    }
  };
  return (
    <div className="flex flex-col items-center w-full p-1 h-full justify-between">
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attezione!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sei sicuro di voler eliminare l'alimento con Id {selectedAlimento} ?
          <p className="text-orange-900">{messageDelete}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Annulla
          </Button>

          <Button variant="danger" onClick={removeAlimento}>
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="flex w-100 justify-between mx-2 p-2">
        <Button
          className="bg-[#802727] text-white hover:bg-[#802727] text-white"
          onClick={() => handleShow(true)}
        >
          Crea un nuovo alimento
        </Button>
        <div className="flex  justify-end m-2"> {subHeaderComponentMemo}</div>
      </div>
      <AlimentsCU show={show} id={selectedAlimento} handleClose={handleClose} />
      <div style={{ height: "70vh" }} className=" overflow-y-scroll">
        <div className="mb-4 grid grid-cols-4 max-md:grid-cols-2 max-sm:grid-cols-1 grid-rows-1 gap-1 w-full px-2 ">
          {filteredItems.map((alimento) => {
            return (
              <div className="p-2" key={alimento.id_alimento}>
                <Card>
                  <Card.Body>
                    <div className="flex items-center">
                      <img
                        alt="img-blur-shadow"
                        className="w-12 h-12"
                        src={"/alimenti/" + alimento.icona + ".png"}
                      />
                      <div className="mx-3">{alimento.nome}</div>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    <div className="flex justify-evenly">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tooltipRemoveAlimento}
                      >
                        <Button
                          onClick={() => {
                            setSelectedAlimento(alimento.id_alimento);
                            handleShowModal();
                          }}
                        >
                          <FaTrash />
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tooltipUpdateAlimento}
                      >
                        <Button
                          onClick={() => {
                            setSelectedAlimento(alimento.id_alimento);
                            handleShow(false);
                          }}
                        >
                          <FaPen />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </Card.Footer>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default AlimentiList;
