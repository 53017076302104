import React, { useEffect, useState } from "react";
import { Form, Toast, ToastContainer } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { Loading, useRequest } from "../../utils";

const NotificationRead = () => {
  const req = useRequest();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [notifica, setNotifica] = useState({});
  const [utenti, setUtenti] = useState([]);

  useEffect(() => {
    getNotifica();
  }, []);

  const getNotifica = async () => {
    let res = await req.get(`/notification/detail/${id}`);
    console.log("RESULT GET notifica:");
    console.log(res);
    delete res.success;
    delete res.error;
    delete res.message;
    const notifica = {
      testo: res.data.data[0].testo,
      dataAgg: new Date(res.data.data[0].dataAgg),
    };
    const utenti = res.data.data.map((user) => ({
      idUtente: user.id_utente,
      datalettura: user.dataLettura,
      letto: user.letto,
      nome: user.nome,
      cognome: user.cognome,
    }));
    setUtenti(utenti);
    setNotifica(notifica);
    setLoading(false);
  };

  return (
    <div className="w-full h-full">
      <ToastContainer className="p-3" position="top-end">
        <Toast
          bg="danger"
          delay={3000}
          autohide
          onClose={() => setShowAlert(false)}
          show={showAlert}
        >
          <Toast.Header>Attenzione!</Toast.Header>
          <p className="m-2">{alertMessage}</p>
          <hr />
        </Toast>
      </ToastContainer>

      <div className="flex flex-col items-center h-full m-2">
        <Loading className="my-auto" loading={loading}>
          <div id="header" className="flex w-full justify-between">
            <h1 className="text-[#802727] text-4xl">Notifica </h1>
          </div>
          <div className="flex flex-col w-full ">
            <div className="flex flex-col justify-start">
              <div className="p-2">
                <Form.Label htmlFor="inputTesto">Testo:</Form.Label>
                <Form.Control
                  defaultValue={notifica.testo}
                  id="inputTesto"
                  as="textarea"
                  rows={5}
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-col justify-start">
              <div className="p-2">
                <Form.Label htmlFor="inputTesto">Data invio:</Form.Label>
                <Form.Control
                  defaultValue={
                    notifica.dataAgg != undefined
                      ? notifica.dataAgg.getDate() +
                        "/" +
                        (notifica.dataAgg.getMonth() + 1) +
                        "/" +
                        notifica.dataAgg.getFullYear() +
                        " " +
                        notifica.dataAgg.getHours() +
                        ":" +
                        (notifica.dataAgg.getMinutes() <= 9
                          ? "0" + notifica.dataAgg.getMinutes()
                          : notifica.dataAgg.getMinutes())
                      : ""
                  }
                  id="inputTesto"
                  as="input"
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-col">
              <div className=" p-2">
                <h1>Utenti a cui è arrivata la notifica</h1>
              </div>
              <div className="px-2 flex">
                {utenti.map((utente) => (
                  <div
                    key={utente.idUtente}
                    className="border  border-2 m-2 p-2 flex border-primary rounded-md"
                  >
                    {utente.nome} {utente.cognome}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full"></div>
        </Loading>
      </div>
    </div>
  );
};

export default NotificationRead;
