import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { FaEye, FaTrash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { Loading, useRequest } from "../../utils";

const DietsRecipes = () => {
  const req = useRequest();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);

  const [dietRecipes, setDietRecipes] = useState([]);
  const [dietName, setDietName] = useState("");

  const [data, setData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterText, setFilterText] = useState("");

  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState("-1");

  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      await getDietRecipes();
      await getAllRecipes();
      await getDietName();
      setLoading(false);
    };
    getData();
  }, []);

  const getDietRecipes = async () => {
    let res = await req.get(`/recipe/bydiet/` + id);
    console.log("RESULT GET RECIPE BY DIET:");
    console.log(res);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    setDietRecipes(res.data.data);
  };

  const getRecipe = async (id) => {
    let res = await req.get(`/recipe/` + id);
    console.log("RESULT GET RECIPE BY ID:");
    console.log(res);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    return res.data;
  };

  const getDietName = async () => {
    
    let res = await req.get(`/diet/name/` + id);
    console.log("RESULT GET DIET NAME:");
    console.log(res);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    setDietName(res.data.tipo);
  };

  const getAllRecipes = async () => {
    let res = await req.get(`/recipe/all`);
    console.log("RESULT GET RECIPES ALL:");
    console.log(res);
    delete res.data.success;
    delete res.data.error;
    delete res.data.message;
    setData(res.data.data);
    setFilteredItems(res.data.data);
  };
  const saveDietRecipes = async () => {
    setLoading(true);
    var listRecipes = dietRecipes.map((rec) => rec.id_ricetta);
    let res = await req.put(`/diet/associateRecipes/` + id, {
      listRecipes: listRecipes,
    });
    console.log("RESULT ASSOCIATE RECIPES TO DIET:");
    console.log(res);
    setLoading(false);
  };

  const goFilter = async (filter) => {
    let tmpFiltered = data.filter(
      (recipe) =>
        recipe.nome && recipe.nome.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredItems(tmpFiltered);
    setFilterText(filter);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
        setFilteredItems(data);
      }
    };

    return (
      <input
        placeholder="Cerca per nome"
        className="border border-solid rounded-lg border-gray pl-4"
        type="text"
        value={filterText}
        onChange={(e) => {
          goFilter(e.target.value);
        }}
      />
    );
  }, [filterText]);

  const removeRecipe = (id) => {
    var newDietRecipes = dietRecipes.filter(
      (recipe) => recipe.id_ricetta != id
    );
    setDietRecipes(newDietRecipes);
  };
  const isCheckRecipe = (id) => {
    var recipe = dietRecipes.find((recipe) => recipe.id_ricetta == id);
    return recipe != null;
  };
  const changeDietRecipes = async (recipeToChange) => {
    var newRecipe = dietRecipes.find(
      (recipe) => recipe.id_ricetta == recipeToChange.id_ricetta
    );
    var newDietRecipes = [];
    if (newRecipe == null) {
      newRecipe = await getRecipe(recipeToChange.id_ricetta);
      newDietRecipes = [...dietRecipes, newRecipe];
    } else {
      newDietRecipes = dietRecipes.filter(
        (recipe) => recipe.id_ricetta != recipeToChange.id_ricetta
      );
    }
    setDietRecipes(newDietRecipes);
  };
  return (
    <div className="flex w-full p-1 h-full justify-between">
      <Modal
        show={showAlertMessage}
        onHide={() => setShowAlertMessage(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attezione!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Vuoi davvero lasciare la pagina senza salvare le modifiche?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAlertMessage(false)}
          >
            Rimani
          </Button>

          <Button
            variant="danger"
            onClick={() => navigate("/recipes/read/" + selectedRecipe)}
          >
            Vai alla pagina della ricetta
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Seleziona le ricette da associare alla dieta
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="flex  justify-end m-2">
              {subHeaderComponentMemo}
            </div>
            {filteredItems.map((recipe) => {
              return (
                <div key={recipe.id_ricetta} className="p-2">
                  <Card>
                    <Card.Body>
                      <div className="flex ">
                        <Form.Check
                          className="p-1"
                          type="checkbox"
                          id="default-checkbox"
                          defaultChecked={isCheckRecipe(recipe.id_ricetta)}
                          onChange={() => {
                            changeDietRecipes(recipe);
                          }}
                        />
                        <div className="flex p-1">
                          {recipe.nome} di {recipe.nome_alimento}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)}>Salva</Button>
        </Modal.Footer>
      </Modal>
      <div className="w-full h-full justify-center">
        <Loading className="my-auto" loading={loading}>
          <div className="flex  w-full justify-between mt-2">
            <h1 className="text-[#802727] text-4xl">
              Gestisci ricette per {dietName}
            </h1>
            <Button
              className=" bg-[#802727] text-white hover:bg-[#802727] text-white mx-3"
              onClick={() => saveDietRecipes()}
            >
              Salva
            </Button>
          </div>
          <div className="w-full h-full overflow-y-scroll mt-4">
            <div className="flex w-100 justify-between mx-2 p-2">
              <div className="p-1 text-2xl">Ricette associate</div>
              <Button className="mx-4" onClick={() => setShowModal(true)}>
                Aggiungi ricetta
              </Button>
            </div>
            <div className="mb-4 w-full px-2 flex grid grid-cols-3 gap-4">
              {dietRecipes.map((recipe) => {
                return (
                  <div className="p-2" key={recipe.id_ricetta}>
                    <Card>
                      <Card.Body>
                        <div className="flex flex-col">
                          <div className="mx-3 items-center text-amber-900 text-xl">
                            {recipe.nome} di {recipe.ricAlimento}
                          </div>
                          <div className="flex justify-start flex-col">
                            <div className="flex flex-col w-full">
                              <p>Ingredienti:</p>
                              {recipe.ingredienti.map((ing) => {
                                return (
                                  <div className="text-sm"> ▹ {ing.nome}</div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <div className="flex justify-evenly">
                          <Button
                            onClick={() => {
                              removeRecipe(recipe.id_ricetta);
                            }}
                          >
                            <FaTrash />
                          </Button>
                          <Button
                            onClick={() => {
                              setSelectedRecipe(recipe.id_ricetta);
                              setShowAlertMessage(true);
                            }}
                          >
                            <FaEye />
                          </Button>
                        </div>
                      </Card.Footer>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
        </Loading>
      </div>
    </div>
  );
};
export default DietsRecipes;
