import React from "react";
import { Dropdown } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logosemplice.png";
import { logout } from "../utils";

export const TopBar = () => {
  let navigate = useNavigate();
  const checkLogout = () => {
    logout();
    navigate("/login");
  };

 

  return (
    <div className="flex flex-row p-2 justify-between h-24">
      <Link to="/homepage" className="w-fit">
        <img src={logo} className=" h-20  rounded"></img>
      </Link>
      <div className="m-4">
        <Dropdown   >
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <FaUser size={30} color="#802727" ></FaUser>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.ItemText>
              Nome: {localStorage.getItem("nome")}
            </Dropdown.ItemText>
            <Dropdown.ItemText>
              Cognome: {localStorage.getItem("cognome")}
            </Dropdown.ItemText>
            <Dropdown.Divider></Dropdown.Divider>
            <Dropdown.Item onClick={checkLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
