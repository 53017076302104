import React, { useState } from "react";
import { Alimento } from "./Alimento";
import { AlimentoRO } from "./AlimentoRO";

const Pasto = (props) => {
  const isUpdate = props.isUpdate;
  const [filteredAlimenti, setFilteredAlimenti] = useState(props.alimenti);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [readOnly, setReadOnly] = useState(props.readOnly || false);
  const [selezionati, setSelezionati] = useState(props.selezionati);
  const pasto =
    props.pasto != null
      ? readOnly
        ? props.pasto.split("_").length == 2
          ? props.pasto.split("_")[0]
          : null
        : props.pasto
      : null;
  const idCategoria = props.idCategoria;

  const unitaMisura = props.unitaMisura;
  const alimenti = props.alimenti;

  const hanldeSelezionato = (alimento) => {
    let index = selezionati.findIndex(
      (p) => p.id_alimento === alimento.id_alimento
    );
    var newSelezionati;
    if (index >= 0) {
      newSelezionati = selezionati.filter(
        (p) => p.id_alimento !== alimento.id_alimento
      );
    } else {
      newSelezionati = [...selezionati, alimento];
    }
    setSelezionati(newSelezionati);
    props.updateSelezionati(pasto, newSelezionati, idCategoria);
  };

  const saveAlimento = (alimento) => {
    var newSelezionati = selezionati.filter(
      (el) => el.id_alimento != alimento.id_alimento
    );
    newSelezionati = [...newSelezionati, alimento];
    setSelezionati(newSelezionati);
    props.updateSelezionati(pasto, newSelezionati);
  };

  const goFilter = async (filter) => {
    let tmpFiltered = alimenti.filter(
      (alimento) =>
        alimento.nome &&
        alimento.nome.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredAlimenti(tmpFiltered);
    setFilterText(filter);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        setFilteredAlimenti(alimenti);
      }
    };

    return (
      <input
        placeholder="Cerca per nome"
        className="border border-solid rounded-lg border-gray pl-4"
        type="text"
        value={filterText}
        onChange={(e) => {
          goFilter(e.target.value);
        }}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="flex flex-col  w-full  p-2 ">
      <div className="w-full">
        <h1 className="text-center text-xl">
          {readOnly
            ? pasto != null
              ? "Alimenti selezionati per " + pasto
              : null
            : "Seleziona gli alimenti per " + pasto}
        </h1>
        {readOnly ? null : (
          <div className="flex justify-end"> {subHeaderComponentMemo}</div>
        )}
        <div className="h-96 overflow-y-scroll mt-2">
        <div className=" h-full mb-4 grid grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-1 w-full p-2 ">
          {readOnly ? (
            alimenti != null ? (
              alimenti.alimenti.map((alimento, index) => {
                return (
                  <div key={alimento.id_alimento + pasto} className="h-min">
                    <AlimentoRO alimento={alimento}></AlimentoRO>
                  </div>
                );
              })
            ) : (
              <div></div>
            )
          ) : (
            filteredAlimenti
              .filter((e) => {
                return e !== undefined;
              })
              .map((alimento, index) => {
                var newAlimento = {
                  id_alimento: alimento.id_alimento,
                  nome: alimento.nome,
                  icona: alimento.icona,
                };
                var selezionato = selezionati.find(
                  (el) => el.id_alimento == alimento.id_alimento
                );

                if (selezionato != null) {
                  newAlimento.selezionato = true;
                  newAlimento.dosaggio1 = selezionato.dosaggio1;
                  newAlimento.unitaMisura1 = selezionato.unitaMisura1;
                  newAlimento.dosaggioDesc = selezionato.dosaggioDesc;
                  newAlimento.alternative =
                    selezionato.alternative != null
                      ? selezionato.alternative
                      : [];
                }

                return (
                  <div key={alimento.id_alimento + pasto}>
                    <Alimento
                      alimento={newAlimento}
                      hanldeSelezionato={hanldeSelezionato}
                      alternativeUM={unitaMisura}
                      saveAlimento={saveAlimento}
                      alternative={alimenti.filter((el) => {
                        return el.id_alimento != newAlimento.id_alimento;
                      })}
                    />
                  </div>
                );
              })
          )}
        </div>
        </div>
      </div>
    </div>
  );
};

export default Pasto;
